import axios from 'axios'
import req, { CMS_URL } from 'utils/request'
import qs from 'querystring'
import { IExamMode, IvolunteerLocation, IrankScoreCheck } from './interface'
import { RES_TYPES } from '../commonInterface'

export async function CMSDATA(): Promise<any> {
  return axios.get(CMS_URL as string)
}

export const getVolunteer = (): RES_TYPES<any> => req.get('college/volunteer/query')

export const volunteerSave = (params: any): RES_TYPES<any> =>
  req.post('college/volunteer/save', params)

export const volunteerUpdate = (params: any): RES_TYPES<any> =>
  req.post('college/volunteer/update', params)

export const volunteerExamMode = (params): RES_TYPES<IExamMode[]> =>
  req.get(`college/volunteer/examMode?${qs.stringify(params)}`)

export const volunteerLocation = (): RES_TYPES<IvolunteerLocation> =>
  req.get(`college/config/provinceConfig`)

/**
 * api: https://yapi.inner.youdao.com/project/146/interface/api/97043
 * 查看用户排名是否正确
 */
export const userRankScoreCheck = (): RES_TYPES<IrankScoreCheck> =>
  req.get(`college/volunteer/userRankScoreCheck`)

/**
 * api: https://yapi.inner.youdao.com/project/146/interface/api/97169
 * 确认无误
 */
export const updateUserRankSign = (): RES_TYPES<boolean> =>
  req.post('college/volunteer/updateUserRankSign')

// 根据省份获取最高分
export const getProvinceMaxScore = (params: { provinceName: string }): RES_TYPES<number> =>
  req.get(`college/config/provinceMaxScore?${qs.stringify(params)}`)
