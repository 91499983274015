import React, { useEffect, useState, useMemo } from 'react'
import ElectiveSubjects from 'components/ElectiveSubjects'
import { ISubject } from 'store/interface'
import { VOLUNTEER_DEFAULTVALUE } from 'constants/index'
import s from './index.module.less'

interface IProps {
  gkYear: number
  gkProvince: string
  value?: any
  contentWidth?: number
  // eslint-disable-next-line no-unused-vars
  onChange?: (arg: any) => void
}

interface IData {
  isSelectCourse: boolean
  volunteerTemplateFirstDto?: ISubject | null
  volunteerTemplateSecondDto?: ISubject | null
  volunteerTemplateThirdDto?: ISubject | null
}

const OPTIONS = [
  {
    label: '未选科',
    value: false
  },
  {
    label: '已选科',
    value: true
  }
]

const defaultValue = {
  isSelectCourse: false,
  ...VOLUNTEER_DEFAULTVALUE
}

const SubjectSelect: React.FC<IProps> = ({ gkProvince, gkYear, value, onChange, contentWidth }) => {
  const [error, setError] = useState<string[]>([])
  const [data, setData] = useState<IData>(defaultValue as IData)

  const handleChange = (payload) => {
    setData(payload)
    if (onChange) onChange(payload)
  }

  const handleClick = (option) => {
    if (option.value === data.isSelectCourse) return
    if (option.value) {
      const emptyArr: string[] = []
      if (!gkProvince) emptyArr.push('高考省份')
      if (!gkYear) emptyArr.push('高考年份')
      if (emptyArr.length) {
        setError(emptyArr)
        return
      }
      setError([])
      handleChange({
        ...data,
        isSelectCourse: option.value
      })
      return
    }
    handleChange({
      ...data,
      ...defaultValue
    })
  }

  useEffect(() => {
    if (value) {
      setData(value)
    }
  }, [value])

  const handleData = (val) => {
    handleChange({
      ...data,
      ...val
    })
  }

  const electiveSubjectsData = useMemo(() => {
    const {
      isSelectCourse,
      volunteerTemplateFirstDto,
      volunteerTemplateSecondDto,
      volunteerTemplateThirdDto
    } = data
    if (isSelectCourse) {
      return {
        volunteerTemplateFirstDto,
        volunteerTemplateSecondDto,
        volunteerTemplateThirdDto
      }
    }
    return {}
  }, [data, gkProvince, gkYear])

  return (
    <div className={s.formList}>
      <span className={s.formListLabel}>
        <span className={s.required}>*</span>
        选科情况
      </span>
      <div className={s.formListContent}>
        <ul className={s.subjectSelect} style={{ width: contentWidth }}>
          {OPTIONS.map((option) => (
            <button
              type="button"
              key={option.label}
              onClick={() => handleClick(option)}
              className={Boolean(data?.isSelectCourse) === option.value ? s.active : ''}
            >
              {option.label}
            </button>
          ))}
        </ul>
        {data?.isSelectCourse && (
          <ElectiveSubjects
            isSelectCourse={Boolean(data?.isSelectCourse)}
            gkProvince={gkProvince}
            gkYear={gkYear}
            value={electiveSubjectsData}
            styleType="col"
            onChange={handleData}
          />
        )}
        {error?.length ? (
          <p className={`${s.formListTips} ${s.required}`}>请选择{error.join('，')}</p>
        ) : null}
      </div>
    </div>
  )
}

SubjectSelect.defaultProps = {
  value: undefined,
  contentWidth: 376,
  onChange: () => {}
}

export default SubjectSelect
