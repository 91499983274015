import React from 'react'
import img from 'static/images/logo.png'
import s from './index.module.less'

const Footer: React.FC<{}> = () => {
  return (
    <div className={s.Footer}>
      <img src={img} alt="" />
      <h6>
        <span>客服：020-2818-5110（周一至周日 9:00-24:00）</span>
        <span>邮箱：shengxuefwzx@163.com</span>
      </h6>
      <div className={s.line} />
      <p>
        违法和不良信息举报电话（涉未成年人）020-2818-5110｜举报邮箱（涉未成年人）shengxuefwzx@163.com｜
        <a href="https://www.12377.cn/" target="_blank" rel="noreferrer">
          网上有害信息举报专区
        </a>
        ｜
        <a href="https://jubao.163.com/" target="_blank" rel="noreferrer">
          网易廉政举报
        </a>
        ©2020网易公司 &nbsp;
        <a href="https://c.youdao.com/volunteer/privacy.html" target="_blank" rel="noreferrer">
          隐私政策
        </a>
        &nbsp; 粤ICP备2022027625号 粤公网安备 44010602010206号
      </p>
    </div>
  )
}

export default Footer
