import React, { CSSProperties, ReactNode, useEffect } from 'react'
import { getQueryString } from 'utils/util'
import s from './index.module.less'
import topImg from './close.png'

type IFooter = 'ok' | 'okAndCancel'

interface IProps {
  visible: boolean
  footer?: IFooter | ReactNode | null
  closable?: boolean
  title?: string
  titleSize?: 'small' | 'middle' | 'large'
  subTitle?: string
  onOk?: () => void
  onCancel?: () => void
  okButtonDisabled?: boolean
  okButtonText?: string
  okButtonStyle?: CSSProperties
  cancelButtonText?: string
  cancelButtonStyle?: CSSProperties
  wrapClassName?: string
  wrapContentClassName?: string
  maskClosable?: boolean
  // pad 个人中心蓝色样式按钮
  padButton?: boolean
}

const Modal: React.FC<IProps> = ({
  visible,
  footer,
  closable,
  children,
  title,
  titleSize,
  subTitle,
  onOk,
  onCancel,
  okButtonDisabled,
  okButtonText,
  okButtonStyle,
  cancelButtonText,
  cancelButtonStyle,
  wrapClassName,
  wrapContentClassName,
  maskClosable,
  padButton
}) => {
  const renderOkFooter = () => {
    const handleOk = (e) => {
      e.stopPropagation()
      onOk?.()
    }
    return (
      <div className={s.flexCenter}>
        <button
          type="button"
          onClick={handleOk}
          className={`${s.okBtn} ${padButton ? s.padBtn : ''}`}
          style={okButtonStyle}
          disabled={okButtonDisabled}
        >
          {okButtonText}
        </button>
      </div>
    )
  }

  const renderOKAndCancelFooter = () => {
    const handleOk = () => {
      onOk?.()
    }
    const handleCancel = () => {
      onCancel?.()
    }
    return (
      <div className={s.flexCenter}>
        <button
          type="button"
          onClick={handleCancel}
          className={`${s.cancelBtn} ${s.btn} ${padButton ? s.padCancelBtn : ''}`}
          style={cancelButtonStyle}
          // disabled={okButtonDisabled}
        >
          {cancelButtonText}
        </button>
        <button
          type="button"
          onClick={handleOk}
          className={`${s.okBtn2} ${s.btn} ${padButton ? s.padBtn : ''}`}
          disabled={okButtonDisabled}
        >
          {okButtonText}
        </button>
      </div>
    )
  }

  const handleCancel = () => {
    onCancel?.()
  }

  const renderTopClose = () => {
    return (
      <div className={s.topClose}>
        <button type="button" onClick={handleCancel}>
          <img src={topImg} alt="" />
        </button>
      </div>
    )
  }

  useEffect(() => {
    const bindClick = (e) => {
      if (e.target.id === 'modal' && maskClosable) {
        // 只为当前弹窗执行操作 pad点击空白处不隐藏
        if (visible && getQueryString('port') !== 'pad') onCancel?.()
      }
    }
    document.addEventListener('click', bindClick)
    return () => document.removeEventListener('click', bindClick)
  }, [visible])

  const renderFooter = () => {
    if (footer) {
      if (footer === 'ok') {
        return renderOkFooter()
      }
      if (footer === 'okAndCancel') {
        return renderOKAndCancelFooter()
      }
      return footer
    }
    return null
  }

  return (
    <>
      {visible && (
        <section className={s.modalBox} id="modal">
          <section
            className={`${s.modalMain} ${wrapClassName}`}
            style={{ paddingBottom: footer ? 32 : 0 }}
          >
            {closable ? renderTopClose() : null}
            {title && (
              <section
                className={`${s.title} ${s[titleSize as string]}`}
                style={{ marginTop: closable ? 0 : 16, marginBottom: subTitle ? 4 : 0 }}
              >
                {title}
              </section>
            )}
            {subTitle ? <section className={s.subTitle}>{subTitle}</section> : null}
            <section className={`${s.content} ${wrapContentClassName}`}>{children}</section>
            {renderFooter()}
          </section>
        </section>
      )}
    </>
  )
}

Modal.defaultProps = {
  footer: null,
  closable: true,
  title: '',
  titleSize: 'middle',
  subTitle: '',
  onOk: undefined,
  onCancel: undefined,
  okButtonDisabled: false,
  okButtonText: '确定',
  okButtonStyle: undefined,
  cancelButtonText: '取消',
  cancelButtonStyle: undefined,
  wrapClassName: '',
  wrapContentClassName: '',
  maskClosable: true,
  padButton: false
}

export default Modal
