import { API_COURSE_SERVER, LOGIN_AUTH_URL } from 'config'
import axios from 'axios'
import req from '../utils/request'

export const userStatus = () =>
  axios.get(`${API_COURSE_SERVER}api/user_status.jsonp`, {
    withCredentials: true
    // params: { api_ver: '1.10.0', api_version: '1.2' }
  })

export async function loginOut(): Promise<any> {
  return axios.get(`${LOGIN_AUTH_URL}/se/reset`, {
    withCredentials: true,
    params: { product: 'DICT', app: 'ke', newjson: true, version: '2.0' }
  })
}

export async function mobLogIn(cf = 7, tp = 'urs-phone-web'): Promise<any> {
  return axios.get(
    `${LOGIN_AUTH_URL}/login?app=web&product=DICT&tp=${tp}&cf=${cf}&newjson=true&version=2.0`,
    {
      withCredentials: true
    }
  )
}
export const personinfoExists = (): Promise<any> => req.get('college/personinfo/exists')

export const deletVolunteer = (): Promise<any> =>
  req.get('college/volunteer/delete', {
    headers: { authorization: 'tiku' }
  })

// 获取用户手机号
export async function getMobile(): Promise<any> {
  return axios.get(`${LOGIN_AUTH_URL}/query/accountinfo`, {
    withCredentials: true
  })
}

// 补充用户手机号
export const fillMobile = (params): Promise<any> =>
  req.post('college/config/userBasicProfileOp/updateBasicProfile', params)
