import React, { CSSProperties } from 'react'
import s from './index.module.less'

export type ITriangleDirection = 'up' | 'down' | 'left' | 'right'

interface IProps {
  width?: number
  direction?: ITriangleDirection
  style?: CSSProperties | null
}

const Triangle: React.FC<IProps> = ({ width, direction, style }) => {
  const classNames = {
    left: s.left,
    right: s.right
  }
  let borderWidth = ''
  const count = `${width}px`
  switch (direction) {
    case 'up':
    case 'right':
      borderWidth = `0 ${count} ${count} ${count}`
      break
    case 'down':
    case 'left':
      borderWidth = `${count} ${count} 0 ${count}`
      break
    default:
      break
  }

  return (
    <div
      className={`${s.triangle} ${classNames[direction as ITriangleDirection]}`}
      style={{ borderWidth, ...style }}
    />
  )
}

Triangle.defaultProps = {
  width: 6,
  direction: 'down',
  style: null
}

export default Triangle
