import React, { useEffect, useState } from 'react'
import useStore from 'store'
// import { xor } from 'lodash-es'
import VolunteerModal from 'components/VolunteerModal'
import { useHistory } from 'react-router-dom'
import { resetParams } from 'constants/index'
import { addRlog } from 'utils/util'
import { bannerList, volunteerTypeList } from './config'
import s from './index.module.less'
// import { checkCanUseGPT } from '../../servers/gpt'

// const gptList = ['volunteerWiki', 'volunteer1v1']

const Banner: React.FC<{}> = () => {
  const history = useHistory()
  const { state, dispatch } = useStore()
  const { activeBanner } = state
  const [modalVisible, setModalVisible] = useState(false)
  const { volunteerData, userInfo } = state

  // useEffect(() => {
  //   checkCanUseGPT().then((res) => {
  //     if (res.msg === '成功') {
  //       const list: string[] = []
  //       res.data.userPermissions.forEach((item) => {
  //         if (item.hasPermission) {
  //           list.push(gptList[item.type])
  //         }
  //       })
  //       dispatch({ type: 'updateDisableGptList', payload: xor(list, gptList) })
  //     }
  //   })
  // }, [])

  // 获取本地数据到购物车数据
  const getCarData = async () => {
    const localData = localStorage.getItem(`${volunteerData?.gkProvince}${userInfo?.id}`) || '[]'
    dispatch({
      type: 'updateVolunteerCar',
      payload: JSON.parse(localData) || []
    })
  }

  const toPage = (id = '') => {
    if (id === 'collegesVolunteer') {
      getCarData()
      history.push({
        pathname: `/collegesVolunteer`,
        search: `?type=1`
      })
    } else if (id === 'professionalVolunteer') {
      getCarData()
      history.push({
        pathname: `/collegesVolunteer`,
        search: `?type=2`
      })
    } else {
      dispatch({
        type: 'updateFilterParamsData',
        payload: {
          ...resetParams,
          majorParam: { midMajorCategoryList: [] }
        }
      })
      if (id === 'probabilityVolunteer') {
        getCarData()
      }
      history.push(`/${id}`)
    }
  }

  const checkLogin = (needCheckVol = false, id = '') => {
    let LoginFlag = true
    if (state.userInfo && !state.userInfo?.id) {
      addRlog('dialog', {
        dialog_name: '登录弹窗'
      })
      dispatch({ type: 'updateLoginModel', payload: true })
      LoginFlag = false
    }
    if (
      state.userInfo &&
      state.userInfo?.id &&
      (!state.personinfoExist || state.volunteerData === null)
    ) {
      dispatch({ type: 'updatePersoninfoModel', payload: true })
      if (id) {
        dispatch({ type: 'updateMsgCb', payload: () => toPage(id) })
      }
      LoginFlag = false
    }
    if (
      needCheckVol &&
      state.userInfo &&
      state.userInfo?.id &&
      state.volunteerData &&
      (!state.volunteerData.isSelectCourse || !state.volunteerData?.gkTotalScore)
    ) {
      setModalVisible(true)
      if (id) {
        dispatch({ type: 'updateMsgCb', payload: () => toPage(id) })
      }
      LoginFlag = false
    }
    return LoginFlag
  }

  const clickBanner = (item: { id: string; name: string }) => {
    const { id, name } = item
    if (id.includes('Volunteer')) {
      if (!checkLogin(true, id)) return
      addRlog('page_enter', {
        page_name: item.name,
        source: 'tab填志愿'
      })
      dispatch({ type: 'changeBanner', payload: 'volunteer' })
    } else if (id === 'studyPlan') {
      if (!checkLogin()) return
      window.open('https://ke.youdao.com/tag/3087?Pdt=CourseWeb')
      return
    } else {
      dispatch({ type: 'changeBanner', payload: id })
    }
    if (id === 'volunteer') return
    if (id === 'home') {
      history.push('/')
      // return
    }
    if (!id.includes('Volunteer') && id !== 'home') {
      addRlog('page_enter', {
        page_name: name
      })
    }
    toPage(id)
  }

  const renderVolType = () => {
    return (
      <div className={s.specil}>
        <button type="button">填志愿</button>
        <ul>
          {volunteerTypeList.map((v) => (
            <li key={v.id}>
              <button type="button" onClick={() => clickBanner(v)}>
                {v.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  useEffect(() => {
    const { pathname } = history.location
    if (pathname.includes('Volunteer')) {
      dispatch({ type: 'changeBanner', payload: 'volunteer' })
    }
    if (pathname.includes('volunteerOrder') || pathname.includes('volunteerCard')) {
      dispatch({ type: 'changeBanner', payload: 'volunteerCard' })
    }
    if (pathname === '/') {
      dispatch({ type: 'changeBanner', payload: 'home' })
    }
  }, [history.location.pathname])

  return (
    <div className={s.BannerBox}>
      <ul className={s.Banner}>
        {bannerList
          // .filter((item) => !state.disableGptList.includes(item.id))
          .map((item) => (
            <li
              className={`${s.name} ${activeBanner === item.id ? s.active : ''}`}
              key={`banner-${item.id}`}
            >
              {item.id === 'volunteer' ? (
                renderVolType()
              ) : (
                <button type="button" onClick={() => clickBanner(item)}>
                  {item.name}
                </button>
              )}
              {activeBanner === item.id && <span className={s.bottomLine} />}
            </li>
          ))}
      </ul>
      <VolunteerModal
        visible={modalVisible}
        value={state.volunteerData}
        onCancel={() => {
          setModalVisible(false)
          dispatch({ type: 'changeBanner', payload: 'home' })
        }}
      />
    </div>
  )
}

export default Banner
