/**
 * @description 高考排名
 */

import React, { useEffect, Dispatch, SetStateAction, useState } from 'react'
import { InputNumber } from 'antd'
import { IVolunteerData } from 'store/interface'
import { volunteerRank } from 'servers/common'
import { checkCourse } from 'utils/util'
import useStore from 'store'
import s from './index.module.less'

interface IProps {
  data?: IVolunteerData | null
  value?: number
  label?: string
  required?: boolean
  contentWidth?: number
  onChange?: Dispatch<SetStateAction<IVolunteerData>>
  isPad?: boolean
}

const GkRank: React.FC<IProps> = ({
  data,
  value,
  required,
  onChange,
  contentWidth,
  label,
  isPad
}) => {
  const { state } = useStore()
  const { provinceYearConfig, volunteerData } = state

  const [rankRange, setRankRange] = useState<string>(volunteerData?.rankRange || '')
  const [year, setYear] = useState<number>()

  const handleChange = (e) => {
    onChange?.({ ...data, rank: e, userRank: e })
  }

  useEffect(() => {
    // 当省份、年份、已选科、输入分数位数为3的时候掉接口查排名
    if (
      data?.gkProvince &&
      data?.gkYear &&
      data?.isSelectCourse &&
      checkCourse(data) &&
      String(data?.gkTotalScore)?.length === 3 &&
      data.rank === undefined
    ) {
      const {
        gkProvince,
        gkYear,
        gkTotalScore,
        isSelectCourse,
        volunteerTemplateFirstDto,
        volunteerTemplateThirdDto,
        volunteerTemplateSecondDto
      } = data
      const params = {
        gkProvince,
        gkYear,
        gkTotalScore,
        isSelectCourse,
        volunteerTemplateFirstDto,
        volunteerTemplateThirdDto,
        volunteerTemplateSecondDto
      }
      volunteerRank(params).then(({ data: rank }) => {
        setRankRange(rank.rankRange)
        setYear(rank?.scoreRankTableYear)
        onChange?.({ ...data, rank: rank.ranking, userRank: rank.ranking })
      })
    }
  }, [data])

  return (
    <div className={`${s.formList} ${isPad ? s.pad : ''}`} style={{ alignItems: 'start' }}>
      <span className={s.formListLabel} style={{ marginTop: 5 }}>
        {required && <span className={s.required}>*</span>}
        {label}
      </span>
      <div className={s.formListContent} style={{ width: contentWidth }}>
        <InputNumber
          value={value}
          placeholder="自动换算，支持修改"
          min={1}
          maxLength={8}
          onChange={handleChange}
        />
        {rankRange && value && (
          <p className={s.formListTips} style={{ color: '#778089' }}>
            您的成绩在该省份最新的
            <span className={s.rankWord}>{provinceYearConfig.scoreRankTableYear || year}</span>
            年一分一段表中排名为
            <span className={s.rankWord}>{rankRange}</span>
            ，系统已使用该区间最低位次自动填写，您可以手动进行更改。
          </p>
        )}
      </div>
    </div>
  )
}

GkRank.defaultProps = {
  data: null,
  value: undefined,
  label: '成绩排名',
  required: false,
  contentWidth: 308,
  onChange: undefined,
  isPad: false
}

export default GkRank
