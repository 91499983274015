/**
 * @description 高考年份
 */

import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import Triangle from 'components/Triangle'
import { getRecentYear } from 'utils/util'
import s from './index.module.less'

interface IProps {
  value?: number
  disabled?: boolean
  selectWidth?: number
  // eslint-disable-next-line no-unused-vars
  onChange?: (arg: string) => void
}

const GkYear: React.FC<IProps> = ({ value, disabled, onChange, selectWidth }) => {
  const [year, setYear] = useState<number>()
  const handleChange = (e) => {
    setYear(e)
    if (onChange) onChange(e)
  }

  useEffect(() => {
    if (value) {
      setYear(value)
    }
  }, [value])

  return (
    <div className={s.formList}>
      <span className={s.formListLabel}>
        <span className={s.required}>*</span>
        高考年份
      </span>
      <div className={s.formListContent}>
        <Select
          style={{ width: selectWidth }}
          value={year}
          suffixIcon={<Triangle />}
          onChange={handleChange}
          placeholder="请选择高考年份"
          disabled={disabled}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {getRecentYear().map((v) => (
            <Select.Option value={v} key={v}>
              {v}
            </Select.Option>
          ))}
        </Select>
        {year && <p className={`${s.formListTips} ${s.required}`}>高考年份一经确认不可修改</p>}
      </div>
    </div>
  )
}

GkYear.defaultProps = {
  value: undefined,
  disabled: false,
  selectWidth: 308,
  onChange: () => {}
}

export default GkYear
