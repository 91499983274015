/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

/**
 * 是否登陆成功的路由守卫组件
 * 如果未登录，跳到首页，如果已登录，跳到相应页
 * 登录状态通过 personinfo/exists 接口得到
 */

import React, { ExoticComponent, useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { personinfoExists } from 'servers/header'
import { getVolunteer } from 'servers/home'

interface IProps {
  component: ExoticComponent
  path: string
  needCheckVolData?: boolean // 是否需要校验高考信息的选科+分数已填
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, needCheckVolData, ...rest }) => {
  const [isShow, setIsShow] = useState(false)
  const [isLogin, setIsLogin] = useState(false)

  const queryInfo = async () => {
    const res = await personinfoExists()
    const isExist = res.code === 0 && res.data
    if (needCheckVolData) {
      const volRes = await getVolunteer()
      const hasVol = volRes.code === 0 && volRes.data?.isSelectCourse && !!volRes.data?.gkTotalScore
      setIsLogin(isExist && hasVol)
    } else {
      setIsLogin(isExist)
    }
    setIsShow(true)
  }

  useEffect(() => {
    queryInfo()
  }, [])

  const renderer = (props) =>
    isLogin ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />
    )

  return <div>{isShow ? <Route {...rest} render={renderer} /> : ''}</div>
}

PrivateRoute.defaultProps = {
  needCheckVolData: false
}

export default PrivateRoute
