/**
 * @description 科目选择组件
 */

import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { SUBJECTS } from 'constants/index'
import img from 'static/images/selected.svg'
import s from './index.module.less'

interface IProps {
  options: string[]
  multiple?: boolean
  onChange: Function
  max?: number
  notifyTitle?: string
  value?: string | string[]
  isPad?: boolean
}

const SelectSubject: React.FC<IProps> = ({
  options,
  multiple,
  onChange,
  max,
  notifyTitle,
  value,
  isPad
}) => {
  const [selected, setSelected] = useState<string | string[]>(multiple ? [] : '')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (value) {
      setSelected(value)
      if (JSON.stringify(value) === '[]') {
        setIsError(false)
      }
    } else {
      setSelected('')
    }
  }, [value])

  const isSelected = (val) => {
    return multiple ? selected.includes(val) : val === selected
  }

  const handleSelect = (val) => {
    let res = multiple ? selected.concat(val) : val
    if (multiple) {
      if (selected.includes(val)) {
        res = res.filter((x) => x !== val)
      }
      if (res.length > max!) {
        setIsError(true)
        return
      }
      setIsError(false)
    } else if (val === selected) {
      res = ''
    }
    onChange(res)
  }

  return (
    <Row className={s.selectSubject}>
      {isError ? (
        <div className={s.error} style={{ bottom: options.length > 4 ? -8 : -20 }}>
          {notifyTitle}只能选择{max}个
        </div>
      ) : null}
      {options.map((val) => {
        return (
          <Col
            key={val}
            onClick={() => handleSelect(SUBJECTS[val])}
            className={`${s.selectSubjectBtn} ${
              isSelected(SUBJECTS[val]) ? (isPad ? s.padSelected : s.selected) : ''
            }`}
            style={{ marginBottom: options.length > 4 ? 12 : '' }}
          >
            {isSelected(SUBJECTS[val]) && !isPad ? <img src={img} alt="" /> : null}
            {val}
          </Col>
        )
      })}
    </Row>
  )
}

SelectSubject.defaultProps = {
  multiple: false,
  max: 2,
  notifyTitle: '该选项',
  value: '',
  isPad: false
}

export default SelectSubject
