import { StateProps } from 'store/interface'

const gloReducer: any = (state: StateProps, action: any) => {
  switch (action.type) {
    case 'updateUserInfo':
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload }
      }
    case 'changeBanner':
      return {
        ...state,
        activeBanner: action.payload
      }
    case 'updateLoginModel':
      return {
        ...state,
        showLoginModel: action.payload
      }
    case 'updateVolunteer':
      return {
        ...state,
        volunteerData:
          JSON.stringify({ ...state.volunteerData, ...action.payload }) === '{}'
            ? null
            : { ...state.volunteerData, ...action.payload } // 兼容原始数据为null的情况
      }
    case 'updatePersoninfoExist':
      return {
        ...state,
        personinfoExist: action.payload
      }
    case 'updatePersoninfoModel':
      return {
        ...state,
        showPersoninfoModel: action.payload
      }
    case 'updateCmsData':
      return {
        ...state,
        cmsData: action.payload
      }
    case 'updateFilterParamsData':
      return {
        ...state,
        filterParamsData: { ...state.filterParamsData, ...action.payload }
      }
    case 'updateVolunteerCar':
      return {
        ...state,
        volunteerCarData: action.payload
      }
    case 'updateGroupIds':
      return {
        ...state,
        volunteerGroupIds: action.payload
      }
    case 'updateLoginCb':
      return {
        ...state,
        afterLoginCb: action.payload
      }
    case 'updateSalePrice':
      return {
        ...state,
        salePrice: action.payload
      }
    case 'updateFillModalVisible':
      return {
        ...state,
        fillModalVisible: action.payload
      }
    case 'updateQuestionList':
      return {
        ...state,
        questionList: action.payload
      }
    case 'updateAnswerStart':
      return {
        ...state,
        answerStart: Date.now()
      }
    case 'updateAnswerWitch':
      return {
        ...state,
        answerWitch: action.payload
      }
    case 'updateMsgCb':
      return {
        ...state,
        afterMsgCb: action.payload
      }
    case 'setAddVolunteerCarParams':
      return {
        ...state,
        addVolunteerCarParams: action.payload
      }
    case 'updateMobileFromUrs':
      return {
        ...state,
        mobileFromUrs: action.payload
      }
    case 'updateConfigData':
      return {
        ...state,
        provinceYearConfig: action.payload
      }
    case 'updateDisableGptList':
      return {
        ...state,
        disableGptList: action.payload
      }
    case 'updateRankCheckModel':
      return {
        ...state,
        rankCheckModel: action.payload
      }
    default:
      return state
  }
}

export default gloReducer
