/**
 * @description 高考年份
 */

import React, { useState, useEffect } from 'react'
import { InputNumber } from 'antd'
import { getProvinceMaxScore } from 'servers/home'
import useStore from 'store'

import s from './index.module.less'

interface IProps {
  gkProvince: string
  value?: number
  label?: string
  required?: boolean
  contentWidth?: number
  // eslint-disable-next-line no-unused-vars
  onChange?: (arg: string) => void
  isPad?: boolean
}

const GkTotalScore: React.FC<IProps> = ({
  gkProvince,
  value,
  onChange,
  contentWidth,
  label,
  required,
  isPad
}) => {
  const { state } = useStore()
  const [score, setScore] = useState<number>()
  const [maxScore, setMaxScore] = useState<number>()
  const handleChange = (e) => {
    setScore(e)
    if (onChange) onChange(e)
  }

  useEffect(() => {
    if (value) {
      setScore(value)
    }
  }, [value])

  useEffect(() => {
    getProvinceMaxScore({ provinceName: gkProvince }).then(({ data }) => setMaxScore(data))
  }, [gkProvince])

  return (
    <div className={`${s.formList} ${isPad ? s.pad : ''}`} style={{ alignItems: 'start' }}>
      <span className={s.formListLabel} style={{ marginTop: 5 }}>
        {required && <span className={s.required}>*</span>}
        {label}
      </span>
      <div className={s.formListContent} style={{ width: contentWidth }}>
        <InputNumber
          value={score}
          placeholder={
            state.cmsData.isRealVolunteer
              ? '请输入真实高考成绩'
              : '可输入预估分数，或最近一次成绩总分'
          }
          max={maxScore || 750}
          min={0}
          onChange={handleChange}
        />
        {score !== undefined && !state.cmsData.isRealVolunteer && (
          <p className={`${s.required} ${s.formListTips}`} style={{ marginTop: 0 }}>
            将按最新一分一段表转化排名推荐
          </p>
        )}
      </div>
    </div>
  )
}

GkTotalScore.defaultProps = {
  value: undefined,
  required: false,
  label: '高考总分',
  contentWidth: 308,
  onChange: () => {},
  isPad: false
}

export default GkTotalScore
