export const API_COURSE_SERVER = 'https://ke.ydshengxue.com/'

// 登录验证接口(http or https)
export const LOGIN_AUTH_URL = 'https://account.ydshengxue.com/login/acc'

export const MOBILE_LOGIN_CONF = {
  // 全局配置
  // 产品ID,【必须】
  product: 'ydls',
  promark: 'XIjAlIp',
  version: 4,
  newCDN: 1,
  host: 'www.ydshengxue.com', // 组件所在域名
  // 滑块验证码宽度,不能小于220，建议大点防止被破解,【必须】
  swidth: '320',
  isHttps: 1,
  // 邮箱登录，邮箱注册，手机帐号登录，手机帐号注册，锁定状态文案,【可选】
  lockConfig: {
    loginLockTxtMb: '登 录'
  },
  includeBox: 'mobileLogin', // 内嵌模式需要插入的Dom节点ID,【可选】
  domains: '163.com,126.com,vip.com,ydshengxue.com', // 登录成功后要设置哪些域cookie
  cookieDomain: 'ydshengxue.com', // 非163域名必选
  skin: 3,
  // cssDomain: 'https://shared-https.ydstatic.com/', // 【可选】,自定义样式的地址，注意：必须是绝对路径，即含有完整的‘http：//’‘https://’协议头
  cssDomain: 'https://shared.ydstatic.com/',
  cssFiles: `ke/login/20210928/volunteerLogin.css?time=${new Date()}`, // 【可选】,自定义样式文件:使用样式覆盖方式进行样式自定义。强烈建议，css在head部分预先加载！
  single: 1,
  // 首屏,【可选】,注册:register，登录:login，根据实际情况填入.(此开关配合mobileFirst 可以确定首屏模块)
  page: 'login',
  customStyles: {
    imagePanel: {
      align: 'bottom' // 易盾在float模式下生效，指定imagePanel出现在controlBar上方还是下方。
    }
  },
  // 手机密码登录相关配置
  // 文案相关
  mbBtnTxt: '立即登录', // 手机密码登录按钮文案,【可选】
  mobilePlaceholder: {
    mobile: '请输入手机号码', // 手机密码登录文案
    pwd1: '请输入密码' // 手机密码登录文案
  },
  // 配置
  needMobileLogin: 1, // 需要手机帐号登录模块,【可选】
  mobileFirst: 1, // 手机帐号相关模块优先展示,【可选】
  smsLoginFirst: 1, // 优先短信登录方式,【可选】
  mbNeedItl: 1, // 手机帐号登录和注册是否支持国际手机号,【可选】
  noqr: 1, // 设置手机帐号是否隐藏二维码登录,【可选】
  // 设置隐私条款
  mbloginclause: {
    list: [
      { url: 'https://hc.reg.163.com/iTerm/doc.html?id=542', txt: '《账号服务条款》' },
      { url: 'https://c.youdao.com/volunteer/guardian.html', txt: '《未成年人保护规则》' },
      { url: 'https://c.youdao.com/volunteer/service.html', txt: '《有道领世升学服务条款》' },
      { url: 'https://c.youdao.com/volunteer/privacy.html', txt: '《有道领世升学隐私政策》' }
    ]
  },
  andTxt: ' ',
  ckboxOk: 1,

  // 手机验证码登录+注册模块相关配置，统一在uniteLogin对象下
  uniteLogin: {
    loginTxt: '立即登录', // 手机验证码登录按钮文案
    isItl: 1, // 是否国际号
    first: 1, // 是否统一登录是首屏
    needClause: 0, // 是否需要条款
    noClauseForce: 0, // 默认勾选条款
    andText: ' ',
    placeholders: {
      mob: '请输入手机号码',
      sms: '请输入验证码'
    },
    uniteLoginTermsList: [
      { url: 'https://hc.reg.163.com/iTerm/doc.html?id=542', name: '《账号服务条款》' },
      { url: 'https://c.youdao.com/volunteer/guardian.html', name: '《未成年人保护规则》' },
      { url: 'https://c.youdao.com/volunteer/service.html', name: '《有道领世升学服务条款》' },
      { url: 'https://c.youdao.com/volunteer/privacy.html', name: '《有道领世升学隐私政策》' }
    ]
  }
}
