/* eslint-disable no-case-declarations */
import { useEffect } from 'react'
import CryptoJS from 'crypto-js'
import { FIRST_SUBJECTS, RESELECT_SUBJECTS } from 'constants/index'
import { savePdfUrl } from '../servers/evaluation'

declare const window: Window & { _rlog: any }

/** 根据环境变量，动态引入 initEruda */
export const initEruda = () => {
  // 开发测试打开
  if ((process.env.REACT_APP_ENV as string).includes('dev')) {
    const script = document.createElement('script')
    script.src = '//cdn.bootcdn.net/ajax/libs/eruda/2.3.3/eruda.js'
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript?.parentNode?.insertBefore(script, firstScript)

    script.onload = function () {
      try {
        // @ts-ignore
        window.eruda?.init()
      } catch (e) {
        console.log('e', e)
      }
    }
  }
}

/**
 * 检查当前选择的是哪种填报类型
 * @returns string
 */
export const checkType = (data) => {
  const key = Object.keys(data).filter(
    (k) => k.includes('volunteer') && data[k] && typeof data[k] === 'object'
  )
  return key.toString()
}

/**
 * 获取最近几年的年份
 * @param val number
 * @returns
 */
export const getRecentYear = (val: number = 4) => {
  const date = new Date()
  let year = date.getFullYear()
  const month = date.getMonth() + 1
  // 9月份之后从目前年份第二年开始
  if (month >= 9) {
    year += 1
  }
  const ret: number[] = []
  for (let i = year; i < year + val; i += 1) {
    ret.push(i)
  }
  return ret
}
/**
 * 加密手机号
 * @param mobile string
 * @returns
 */
export const encryptMobile = (mobile: string) => {
  const reg = /(\d{3})\d{4}(\d{4})/
  return mobile.replace(reg, '$1****$2')
}

/**
 * 根据起始id和循环次数返回响应数组
 * @param startId
 * @param cycleCount
 * @returns
 */
export const getIds = (startId, cycleCount) => {
  const ret: number[] = []
  for (let i: number = startId; ret.length < cycleCount; i -= 1) {
    ret.push(i)
  }
  return ret
}

/**
 * 根据录取概率和冲稳保类型返回数字
 * @param probabilityType
 * @param admissionProbability
 * @returns
 */
export const getNum = (probabilityType, admissionProbability) => {
  if (!probabilityType) {
    if (admissionProbability < 6) {
      return 0
    }
    if (admissionProbability > 95) {
      return 4
    }
  }
  return probabilityType
}

/**
 * 格式化数字为时间
 * @param millisecond 毫秒
 * @return 格式化时长 HH时mm分ss秒
 */
export const formatDuration = (millisecond) => {
  const time = millisecond / 1000

  let hour: string | number = Math.floor(time / 60 / 60)
  hour = hour.toString().padStart(2, '0')

  let minute: string | number = Math.floor(time / 60) % 60
  minute = minute.toString().padStart(2, '0')

  let second: string | number = Math.floor(time) % 60
  second = second.toString().padStart(2, '0')

  return `${hour}时${minute}分${second}秒`
}

/**
 * 打印PDF
 * @param answerId
 * @param userId
 * @param type
 * @param name
 * @param callback
 */
export const printPDF = (
  answerId: number,
  userId: number,
  type: string,
  name: string,
  // eslint-disable-next-line no-unused-vars
  callback: (url: string) => void
) => {
  const baseURL = {
    dev: `https://f2estatic.youdao.com/xuekq/evaluation-report/#/${type}`,
    pre: `https://c.youdao.com/hikari/evaluation//report.html#/${type}`,
    prod: `https://c.youdao.com/hikari/evaluation//report.html#/${type}`
  }[process.env.REACT_APP_ENV || 'dev']

  const requestURL = `${baseURL}?testUserId=${userId}&answerId=${answerId}`
  fetch(
    `https://screenshot.youdao.com/api/gen/pdf?url=${encodeURIComponent(
      requestURL
    )}&format=noFormat&height=4412&width=2480&name=${name}`
  )
    .then((response) => response.json())
    .then(async (data) => {
      callback(data.data.url)
      window.localStorage.setItem(`answerId_${answerId}`, data.data.url)
      await savePdfUrl({ answerId, reportUrl: data.data.url })
    })
    .catch(() => console.error('加载PDF报告错误'))
}

/**
 * 加log
 * @param action 事件
 * @param params 事件参数
 */
export const addRlog = (action: string, params: any) => {
  if (params) {
    /* eslint no-underscore-dangle: 0 */
    Object.keys(params).forEach((k) => window._rlog.push(['_addPost', k, params[k]]))
    window._rlog.push(['_trackEvent', action])
    Object.keys(params).forEach((k) => window._rlog.push(['_removePost', k]))
  } else {
    window._rlog.push(['_trackEvent', action])
  }
}

/* 替换一段文本的关键字样式
 * @param keyword 要替换样式的关键字
 * @param content 替换内容
 * @param color 替换颜色
 * @returns HTML
 */
export const replaceHighlightHTML = (keyword, content, color = '#ff6700') => {
  return content?.replace(keyword, `<span style=color:${color}>${keyword}</span>`)
}

/* 封装localstorage当个自然日不展示
 * @param key
 * @param value
 *
 */
export const setLocalstorage = (key, value) => {
  const obj = {
    data: value,
    time: new Date().getTime()
  }
  localStorage.setItem(key, JSON.stringify(obj))
}

// 取值
export const getLocalstorage = (key: string) => {
  const obj = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : null
  const now = new Date()
  if (
    !(
      now.getFullYear() === new Date(obj?.time).getFullYear() &&
      now.getMonth() === new Date(obj?.time).getMonth() &&
      now.getDate() === new Date(obj?.time).getDate()
    )
  ) {
    window.localStorage.removeItem(key)
    return false
  }
  return true
}

// 改浏览器标签文案
export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = `${title}-有道领世升学规划`
    return () => {
      document.title = '有道领世升学规划'
    }
  }, [title])
}

/**
 * 解析需要转unicode加密后的文案
 * @param word string
 * @returns string
 */
export const genUnicodeWord = (word: string) => {
  const gen = (str: string) => {
    return str
      .split('u')
      .map((item) => {
        if (item.includes(';')) {
          return item.split(';')
        }
        return item
      })
      .flat()
      .filter((x) => x !== '')
  }
  if (word) {
    const index = Array.from(word).findIndex((x) => x === '(')
    if (index !== -1) {
      const prev = word.slice(0, index)
      const next = word.slice(index, word.length)
      return gen(prev).concat(gen(next))
    }
    return gen(word)
  }
  return ''
}

/**
 * AES-128-ECB对称解密
 * @param textBase64 要解密的密文，Base64格式
 * @param secretKey 密钥
 * @returns string 解密后的明文
 */
export const AES_ECB_DECRYPT = (textBase64: string, secretKey: string) => {
  const key = CryptoJS.enc.Utf8.parse(secretKey)
  const decrypt = CryptoJS.AES.decrypt(textBase64, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Utf8.stringify(decrypt)
}

/**
 * 解密字符
 * @param val 值
 * @param schoolId 用于拼key的schoolId
 * @param interfaceName 用于拼key的接口名称（取接口名称最后一个单词）
 * @returns
 */
export const genCryptoVal = (
  val: string | null | undefined | any,
  schoolId: number,
  interfaceName: string
) => {
  if (!val) return '--'
  // 密钥16字节  schoolId+接口名称+空位填充（不够16字节后面补充0）
  let key = `${schoolId}${interfaceName}`
  const curLen = key.length
  if (curLen >= 16) {
    key = key.slice(0, 16)
  } else {
    for (let i = 0; i < 16 - curLen; i += 1) {
      key += '0'
    }
  }
  return AES_ECB_DECRYPT(val, key)
}

/**
 * 获取路由上的值
 * @param 要获取的name
 * @returns val
 */
export const getQueryString = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}

// 校验选择科目是否合法
export const checkCourse = (volInfo) => {
  let required = true
  let template
  const { volunteerTemplateFirstDto, volunteerTemplateSecondDto, volunteerTemplateThirdDto } =
    volInfo
  if (Object.values(volunteerTemplateFirstDto || {}).some((x) => x === true)) {
    template = 'volunteerTemplateFirstDto'
  }
  if (Object.values(volunteerTemplateSecondDto || {}).some((x) => x === true)) {
    template = 'volunteerTemplateSecondDto'
  }
  if (volunteerTemplateThirdDto?.subjectType) {
    template = 'volunteerTemplateThirdDto'
  }
  switch (template) {
    case 'volunteerTemplateFirstDto':
      const firstRes = Object.keys(volunteerTemplateFirstDto!).filter(
        (k) => volunteerTemplateFirstDto[k]
      )
      const first = firstRes.filter((x) => FIRST_SUBJECTS.includes(x))
      const reselect = firstRes.filter((x) => RESELECT_SUBJECTS.includes(x))
      required = !(first.length !== 1 || reselect.length !== 2)
      break
    case 'volunteerTemplateSecondDto':
      const secondRes = Object.keys(volunteerTemplateSecondDto).filter(
        (k) => volunteerTemplateSecondDto[k]
      )
      required = secondRes.length === 3
      break
    case 'volunteerTemplateThirdDto':
      required = !!volunteerTemplateThirdDto?.subjectType
      break
    default:
      required = false
  }
  return required
}
