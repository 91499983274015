import s from './index.module.less'

export const FIRST_PERFER_SUBJECTS = ['物理', '历史']
export const FIRST_RESELECT_SUBJECTS = ['化学', '生物', '政治', '地理']
export const SECOND_SUBJECTS = ['物理', '化学', '生物', '政治', '历史', '地理', '技术']
export const THIRD_SUBJECTS = ['文科', '理科']
export const THIRD_SUBJECTS_OBJ = { 1: '文科', 2: '理科' }

export const VOLUNTEER_TYPE_PROVINCE = {
  // 3+1+2
  volunteerTemplateFirstDto: [
    '河北省',
    '广东省',
    '江苏省',
    '湖南省',
    '湖北省',
    '福建省',
    '重庆市',
    '辽宁省'
  ],
  // 3+3
  volunteerTemplateSecondDto: ['北京市', '山东省', '浙江省', '天津市', '上海市', '海南省'],
  // 文理科
  volunteerTemplateThirdDto: [
    '山西省',
    '河南省',
    '安徽省',
    '四川省',
    '陕西省',
    '江西省',
    '黑龙江省',
    '甘肃省',
    '内蒙古自治区',
    '广西壮族自治区',
    '吉林省',
    '云南省',
    '新疆维吾尔自治区',
    '贵州省',
    '宁夏回族自治区',
    '青海省'
    // '西藏自治区'
  ]
}

export const SUBJECTS = {
  物理: 'physics',
  化学: 'chemistry',
  生物: 'biology',
  政治: 'politics',
  历史: 'history',
  地理: 'geography',
  技术: 'technology'
}

export const FIRST_SUBJECTS = ['physics', 'history']
export const RESELECT_SUBJECTS = ['chemistry', 'biology', 'politics', 'geography']

// 冲稳保对应样式（1冲 2稳 3保）
export const COLOR_STYLE = {
  0: {
    dashboard: {
      from: '#FF9470',
      to: '#FA4B3E'
    },
    toolTip: {
      background: '#FFF2EE',
      color: '#FA4B3E'
    }
  },
  1: {
    dashboard: {
      from: '#FF9470',
      to: '#FA4B3E'
    },
    toolTip: {
      background: '#FFF2EE',
      color: '#FA4B3E'
    }
  },
  2: {
    dashboard: {
      from: '#7bcda5',
      to: '#47b881'
    },
    toolTip: {
      background: '#E9F5EE',
      color: '#47B881'
    }
  },
  3: {
    dashboard: {
      from: '#629af6',
      to: '#3b82f6'
    },
    toolTip: {
      background: '#EBF5FF',
      color: '#3B82F6'
    }
  },
  4: {
    dashboard: {
      from: '#629af6',
      to: '#3b82f6'
    },
    toolTip: {
      background: '#EBF5FF',
      color: '#3B82F6'
    }
  },
  5: {
    dashboard: {
      from: '#629af6',
      to: '#3b82f6'
    },
    toolTip: {
      background: '#EEEFF3',
      color: '#1F242E'
    }
  }
}

// 重置搜索数据
export const resetParams = {
  schoolName: '',
  enrollName: '',
  searchType: 1,
  page: 1,
  size: 10,
  collegeParam: {
    provinceList: [],
    schoolTypes: [],
    schoolTags: [],
    schoolTypeAttribute: []
  },
  majorParam: {
    midMajorCategoryList: []
  }
}

export const abcMap = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
  19: 'T',
  20: 'U',
  21: 'V',
  22: 'W',
  23: 'X'
}

/**
 * ***  目前已经全部从接口获取，不再使用 留存作为省份查看模式所用***
 * 各个地区下对应的模式
 * isSigle: 是否是单专业，没有组，没有院校组
 * hasGroup : 是否有专业组
 * maxVolunter: 购物车最多专业数量
 * maxGroup: 有专业组的情况下，购物车最大专业组数量
 * maxGroupVolunter: 有专业组的情况下，每个组下的最大数量
 * maxGroupAlternative: 有专业组的情况下，每个组下的备选数量
 * hasSchoolGroup: 是否有院校组
 * batchType: 是否分批次，批次详情
 * sortType: 购物车内的排序方式
 */

// export const locationMap = {
//   河北省: {
//     isSigle: true,
//     hasGroup: false,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     hasBatch: true,
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 96
//     },
//     maxScore: 750
//   },
//   山东省: {
//     isSigle: true,
//     hasGroup: false,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     hasBatch: true,
//     batchType: ['一段', '二段'],
//     一段: {
//       maxVolunter: 96
//     },
//     二段: {
//       maxVolunter: 96
//     },
//     maxScore: 750
//   },
//   浙江省: {
//     isSigle: true,
//     hasGroup: false,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     hasBatch: true,
//     batchType: ['一段', '二段'],
//     一段: {
//       maxVolunter: 80
//     },
//     二段: {
//       maxVolunter: 80
//     },
//     maxScore: 750
//   },
//   重庆市: {
//     isSigle: true,
//     hasGroup: false,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     hasBatch: true,
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 96
//     },
//     maxScore: 750
//   },
//   辽宁省: {
//     isSigle: true,
//     hasGroup: false,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     hasBatch: true,
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 112
//     },
//     maxScore: 750
//   },
//   广东省: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 45,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   北京市: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 30,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   天津市: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批A', '本科批B'],
//     本科批A: {
//       maxVolunter: 50,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科批B: {
//       maxVolunter: 25,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   上海市: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 24,
//       maxGroupVolunter: 4,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 660
//   },
//   海南省: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 30,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 900
//   },
//   江苏省: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 40,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   湖南省: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 45,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   湖北省: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 45,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   福建省: {
//     isSigle: false,
//     hasGroup: true,
//     hasSchoolGroup: false,
//     sortType: 'number',
//     batchType: ['本科批'],
//     本科批: {
//       maxVolunter: 40,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   河南省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 6,
//       maxGroupVolunter: 5,
//       maxGroupAlternative: 3
//     },
//     本科第二批: {
//       maxGroup: 9,
//       maxGroupVolunter: 5,
//       maxGroupAlternative: 3
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   四川省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 9,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 9,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   陕西省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 6,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 12,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   江西省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 10,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 10,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   安徽省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 6,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 10,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   黑龙江省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批A', '本科第二批A'],
//     sortType: 'abc',
//     本科第一批A: {
//       maxGroup: 10,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批A: {
//       maxGroup: 10,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   甘肃省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 9,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 9,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   内蒙古自治区: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批A', '本科第二批A'],
//     sortType: 'abc',
//     本科第一批A: {
//       maxGroup: 6,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批A: {
//       maxGroup: 6,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   广西壮族自治区: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 6,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 6,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   吉林省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批A', '本科第二批A'],
//     sortType: 'abc',
//     本科第一批A: {
//       maxGroup: 7,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批A: {
//       maxGroup: 10,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   云南省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 5,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 15,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   新疆维吾尔自治区: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 9,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 18,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   贵州省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   宁夏回族自治区: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 4,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   青海省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: ['本科第一批', '本科第二批'],
//     sortType: 'abc',
//     本科第一批: {
//       maxGroup: 12,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批: {
//       maxGroup: 6,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   },
//   // 西藏自治区: {
//   //   isSigle: false,
//   //   hasGroup: false,
//   //   hasSchoolGroup: true,
//   //   batchType: ['本科第一批', '本科第二批'],
//   //   sortType: 'abc',
//   //   本科第一批: {
//   //     maxGroup: 10,
//   //     maxGroupVolunter: 4,
//   //     maxGroupAlternative: 0
//   //   },
//   //   本科第二批: {
//   //     maxGroup: 10,
//   //     maxGroupVolunter: 4,
//   //     maxGroupAlternative: 0
//   //   },
//   //   hasBatch: true,
//   //   maxScore: 750
//   // },
//   山西省: {
//     isSigle: false,
//     hasGroup: false,
//     hasSchoolGroup: true,
//     batchType: [
//       '本科第一批A',
//       '本科第一批A1',
//       '本科第一批B',
//       '本科第二批A',
//       '本科第二批B',
//       '本科第二批C'
//     ],
//     sortType: 'abc',
//     本科第一批A: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第一批A1: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第一批B: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批A: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批B: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     本科第二批C: {
//       maxGroup: 8,
//       maxGroupVolunter: 6,
//       maxGroupAlternative: 2
//     },
//     hasBatch: true,
//     maxScore: 750
//   }
// }

export const ORDER_STATUS = {
  10: '未支付',
  11: '支付成功',
  12: '已取消',
  0: '已失效'
}

export const PAY_FROM = {
  5: '微信支付',
  6: '支付宝',
  31: '微信支付',
  32: '支付宝',
  24: '抖音支付'
}

export const probabilityTypeObj = {
  0: {
    title: '难',
    className: s.bgRed,
    color: s.Red,
    bg: s.RedBg
  },
  1: {
    title: '冲',
    className: s.bgRed,
    color: s.Red,
    bg: s.RedBg
  },
  2: {
    title: '稳',
    className: s.bgOrigin,
    color: s.Origin,
    bg: s.OriginBg
  },
  3: {
    title: '保',
    className: s.bgBlue,
    color: s.Blue,
    bg: s.BlueBg
  },
  4: {
    title: '保',
    className: s.bgBlue,
    color: s.Blue,
    bg: s.BlueBg
  }
}

// 录取概率列表为空文案
export const PROBABILITY_EMPTY_WORD =
  '由于官方未公布院校的招生录取数据，或院校实际无招生\n导致无法测算概率，请换个院校试试～'

export const SEX_TYPE = [
  {
    id: 1,
    name: '男'
  },
  {
    id: 2,
    name: '女'
  }
]

// 填志愿的默认值
export const VOLUNTEER_DEFAULTVALUE = {
  volunteerTemplateFirstDto: {
    biology: false,
    chemistry: false,
    geography: false,
    history: false,
    physics: false,
    politics: false
  },
  volunteerTemplateSecondDto: {
    biology: false,
    chemistry: false,
    geography: false,
    history: false,
    physics: false,
    politics: false,
    technology: false
  },
  volunteerTemplateThirdDto: {
    subjectType: undefined
  }
}

// 选课模式对应字段
export const MODE = {
  1: 'volunteerTemplateFirstDto',
  2: 'volunteerTemplateSecondDto',
  3: 'volunteerTemplateThirdDto'
}

export const MESSAGE_RLOG = {
  招办动态: 'zhaoban_title',
  高考资讯: 'news_title',
  志愿讲堂: 'video_title'
}

// 不展示录取规则标签的省份
export const notShowRuleTagProvinces = ['河北省', '山东省', '浙江省', '重庆市', '辽宁省']
