import React, { useEffect } from 'react'
import {
  userStatus,
  loginOut,
  personinfoExists,
  deletVolunteer,
  fillMobile,
  getMobile
} from 'servers/header'
import { getPersonInfo } from 'servers/percenter'
import useStore from 'store'
import { useHistory } from 'react-router-dom'
import { encryptMobile, addRlog } from 'utils/util'
import logo from 'static/images/logo.png'
import { Input } from 'antd'
import s from './index.module.less'
import searchIcon from './images/Search.png'
import Login from '../Login'
import PerCenterModal from '../PerCenterModal'

const Header: React.FC<{}> = () => {
  const { state, dispatch } = useStore()
  const history = useHistory()

  const loginOutButton = () => {
    loginOut().then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: 'updateUserInfo',
          payload: {
            username: '',
            id: 0,
            nickname: 'string',
            avatar: '',
            mobile: ''
          }
        })
        localStorage.removeItem('transitionPage')
        localStorage.removeItem('showTip')
        window.location.reload()
      }
    })
  }

  const handlePercenter = () => {
    if (state.userInfo?.id && !state.personinfoExist) {
      dispatch({ type: 'updatePersoninfoModel', payload: true })
      return
    }
    history.push('/percenter')
  }

  useEffect(() => {
    if (!state.userInfo?.id) {
      userStatus().then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'updateUserInfo', payload: res.data })
          dispatch({ type: 'updateMobileFromUrs', payload: res.data.mobile })
          // 为urs接口获取不到手机号打补丁 主站修复后可去掉
          if (!res?.data?.mobile) {
            getMobile().then((mobileRes) => {
              const mobile = mobileRes.data?.data?.third_part_info?.filter(
                (v) => v.platform === 'ursphone'
              )?.[0]?.username
              fillMobile({ mobile })
            })
          }
        }
      })
    } else {
      // 校验是否填写过个人信息
      personinfoExists().then((res) => {
        if (res.code === 0) dispatch({ type: 'updatePersoninfoExist', payload: res.data })
      })
    }
    dispatch({ type: 'updateLoginModel', payload: false })
  }, [state.userInfo?.id])

  useEffect(() => {
    if (state.personinfoExist) {
      getPersonInfo().then((res) => {
        if (res.code === 0) dispatch({ type: 'updateUserInfo', payload: res.data })
      })
    }
  }, [state.personinfoExist])

  const onPressEnter = (e) => {
    const val = e.target.value
    if (val) history.push(`/searchResult?keyword=${val}`)
  }

  const deleteVol = () => {
    deletVolunteer().then(({ code, data }) => {
      if (code === 0 && data) window.location.reload()
    })
  }

  return (
    <section className={s.Header}>
      <section className={s.logBox}>
        <div className={s.logo}>
          <img src={logo} alt="logo" />
        </div>
        {state.volunteerData?.gkProvince ? (
          <div className={s.location}>{state.volunteerData?.gkProvince}</div>
        ) : null}
        {state.volunteerData?.gkProvince && state.userInfo?.superUser ? (
          <button type="button" className={s.changeLocation} onClick={deleteVol}>
            切换
          </button>
        ) : null}
      </section>
      <section className={s.loginBox}>
        <section className={s.searchBox}>
          <img className={s.searchIcon} src={searchIcon} alt="img" />
          <Input className={s.searchInput} placeholder="搜院校/专业" onPressEnter={onPressEnter} />
        </section>

        {state.userInfo?.id ? (
          <section className={`${s.login} ${s.logined}`}>
            <div className={s.Iavatar}>
              <img src={state.userInfo?.avatar} alt="avatar" className={s.avatarImg} />
            </div>
            <button type="button">
              {state.userInfo?.name ||
                (state.userInfo?.mobile && encryptMobile(state.userInfo?.mobile))}
            </button>
            <ul className={s.center}>
              <li>
                <button type="button" onClick={handlePercenter}>
                  个人中心
                </button>
              </li>
              <li>
                <button type="button" onClick={loginOutButton}>
                  退出登录
                </button>
              </li>
            </ul>
          </section>
        ) : (
          <button
            className={s.login}
            type="button"
            onClick={() => {
              addRlog('dialog', {
                dialog_name: '登录弹窗'
              })
              dispatch({ type: 'updateLoginModel', payload: true })
            }}
          >
            <div className={s.avatar} />
            <span className={s.loginBtn}>登录</span>
          </button>
        )}
        {state.showLoginModel && <Login />}
        {state.showPersoninfoModel && <PerCenterModal />}
      </section>
    </section>
  )
}

export default Header
