import req from '../utils/request'

// 清空购物车
export const clearCar = async (): Promise<any> => req.post('college/shoppingcart/clear')

// 查询购物车当前内容
export const queryCarDetail = (): Promise<any> => req.get(`college/shoppingcart/query`)

// 购物车生成志愿表
export const addCarTable = (params): Promise<any> => req.post('college/shoppingcart/save', params)

// 暂存购物车
export const stashCarTable = (params): Promise<any> =>
  req.post('college/shoppingcart/stash', params)
