import React, { createContext, useReducer, useContext } from 'react'
import reducer from '../reducer'
import { StateProps } from './interface'

export const initState: StateProps = {
  // userInfo: { id: 11 },
  userInfo: null,
  activeBanner: 'home',
  showLoginModel: false,
  volunteerData: null,
  personinfoExist: false,
  showPersoninfoModel: false,
  cmsData: {},
  filterParamsData: { searchType: 1, page: 1, size: 10 },
  volunteerCarData: [],
  volunteerGroupIds: [],
  afterLoginCb: null,
  salePrice: null,
  fillModalVisible: false,
  questionList: [],
  answerWitch: 0,
  answerStart: 0,
  afterMsgCb: null,
  addVolunteerCarParams: null,
  mobileFromUrs: '',
  provinceYearConfig: {},
  disableGptList: ['volunteerWiki', 'volunteer1v1'],
  rankCheckModel: false // 校验用户当前位次是否符合系统位次
}

interface IContextProps {
  state: StateProps
  dispatch: ({ type, payload }: { type: string; payload: any }) => void // eslint-disable-line
}

const Context = createContext({} as IContextProps)

// eslint-disable-next-line react/prop-types
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)
  // @ts-ignore
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
}

const useStore = () => useContext(Context)

export default useStore
