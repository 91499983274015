/**
 * @description 省市区关联选择组件
 */

import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { specialProvincesData, citysData, areasData } from 'constants/address'
import Triangle from 'components/Triangle'
import s from './index.module.less'

interface IProps {
  width?: number
  value: object
  onChange: Function
}

interface IAddress {
  province?: string
  city?: string
  county?: string
}

const Address: React.FC<IProps> = ({ width, value, onChange }) => {
  const [baseInfo, setBaseInfo] = useState<IAddress>(value)

  useEffect(() => {
    setBaseInfo(value)
  }, [value])

  const handleBaseInfo = (key, val) => {
    // 当选中省/市/区县无关联时，需清空相应值
    const otherVal: IAddress = {}
    if (key === 'province' && !citysData[val].includes(baseInfo.city)) {
      otherVal.city = undefined
      otherVal.county = undefined
    }
    if (key === 'city' && !areasData[val].includes(baseInfo.county)) {
      otherVal.county = undefined
    }
    const payload = {
      ...baseInfo,
      [key]: val,
      ...otherVal
    }
    setBaseInfo(payload)
    onChange(payload)
  }

  return (
    <>
      <Select
        value={baseInfo?.province}
        style={{ width }}
        onChange={(e) => handleBaseInfo('province', e)}
        placeholder="省"
        suffixIcon={<Triangle />}
        className={s.address}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {specialProvincesData.map((v) => (
          <Select.Option value={v} key={v}>
            {v}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={baseInfo?.city}
        style={{ width }}
        onChange={(e) => handleBaseInfo('city', e)}
        placeholder="市"
        suffixIcon={<Triangle />}
        className={s.address}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {citysData[baseInfo?.province]?.map((v) => (
          <Select.Option value={v} key={v}>
            {v}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={baseInfo?.county}
        style={{ width }}
        onChange={(e) => handleBaseInfo('county', e)}
        placeholder="区/县"
        suffixIcon={<Triangle />}
        className={s.address}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {areasData[baseInfo?.city]?.map((v) => (
          <Select.Option value={v} key={v}>
            {v}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

Address.defaultProps = {
  width: 110
}

export default Address
