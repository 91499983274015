import React, { useEffect, useState } from 'react'
import { MOBILE_LOGIN_CONF } from 'config'
import { userStatus, mobLogIn, personinfoExists, fillMobile, getMobile } from 'servers/header'
import useStore from 'store'
import Modal from 'components/Modal'
import { REDEEMCODE_URL } from 'utils/request'
import { getQueryString } from 'utils/util'
import s from './index.module.less'
import loadingGif from './images/loading.gif'
import iconImg from './images/icon.svg'
import './reset.css'

interface MyWindow extends Window {
  urs1(): void
}
declare let window: MyWindow

const Login: React.FC<{}> = () => {
  const { state, dispatch } = useStore()
  const [loadingShow, setLoadingShow] = useState(false)

  const mobileInitSucc = () => {
    setLoadingShow(false)
  }
  const handleMobileLogin = (phoneNumber, isOther, data) => {
    console.log(phoneNumber, isOther, data, state)
    mobLogIn().then((response) => {
      if (response.data.code !== 0) {
        alert('登录失败，请重试')
      } else {
        userStatus().then((res) => {
          if (res.status === 200) {
            dispatch({ type: 'updateUserInfo', payload: res.data })
            dispatch({ type: 'updateMobileFromUrs', payload: res.data.mobile })
            // 为urs接口获取不到手机号打补丁 主站修复后可去掉
            if (!res?.data?.mobile) {
              getMobile().then((mobileRes) => {
                const mobile = mobileRes.data?.data?.third_part_info?.filter(
                  (v) => v.platform === 'ursphone'
                )?.[0]?.username
                fillMobile({ mobile })
              })
            }
          }
        })
        // 登录成功后立马校验是否填写过个人信息
        personinfoExists().then((res) => {
          if (res.code === 0) {
            if (!res.data) dispatch({ type: 'updatePersoninfoModel', payload: true })
            if (res.data) dispatch({ type: 'updateRankCheckModel', payload: true })
          }
        })
        if (state.afterLoginCb) {
          state.afterLoginCb()
          dispatch({ type: 'updateLoginCb', payload: null })
        }
      }
      dispatch({ type: 'updateLoginModel', payload: false })
    })
  }

  // 手机帐号登录或注册,初始化完成回调
  MOBILE_LOGIN_CONF.mbInitSuccess = () => {
    console.log('手机帐号登录或注册,初始化完成回调')
    mobileInitSucc()
  }
  // 登录成功后回调
  MOBILE_LOGIN_CONF.logincb = (phoneNumber, isOther, data) => {
    try {
      // @ts-ignore
      window.urs1 && window.urs1.loginDolockMb() // eslint-disable-line
      handleMobileLogin(phoneNumber, isOther, data)
    } catch (e: any) {
      console.log(`There has been a problem with logincb: ${e.message}`)
    }
  }

  useEffect(() => {
    // @ts-ignore
    window.urs1 = new URS(MOBILE_LOGIN_CONF) // eslint-disable-line
  }, [])

  return (
    <Modal
      visible
      onCancel={() => dispatch({ type: 'updateLoginModel', payload: false })}
      wrapClassName={s.modal}
      wrapContentClassName={s.wrapContent}
    >
      <p className={s.loginHeadActive}>手机号登录</p>
      <section>
        <section id="mobileLogin" className={s.Login} />
        {loadingShow && <img src={loadingGif} className={s.loading} alt="#" />}
        {/* {!loadingShow && (
          <section className={s.tips}>
            登录代表已同意
            <a className={s.tipsLink} href="https://c.youdao.com/volunteer/privacy.html">
              隐私政策
            </a>
            和
            <a className={s.tipsLink} href="https://c.youdao.com/volunteer/guardian.html">
              未成年人保护规则
            </a>
          </section>
        )} */}
      </section>
      <div className={s.redeem}>
        <button
          type="button"
          onClick={() => {
            if (getQueryString('port') === 'pad') {
              /* eslint-disable */
              // @ts-ignore
              return window?.ydk?._invoke('openWeb', { url: REDEEMCODE_URL('highSchoolPad') })
            }
            return window.open(REDEEMCODE_URL('highSchool'))
          }}
        >
          <span>已获得升学规划卡，前往兑换</span>
          <img src={iconImg} alt="" />
        </button>
      </div>
    </Modal>
  )
}

export default Login
