/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useMemo } from 'react'
import GkProvince from 'components/GkProvince'
import GkYear from 'components/GkYear'
import GkTotalScore from 'components/GkTotalScore'
import GkRank from 'components/GkRank'
import SubjectSelect from 'components/SubjectSelect'
import { addRlog, checkCourse } from 'utils/util'
import { IVolunteerData } from 'store/interface'
import { VOLUNTEER_DEFAULTVALUE } from 'constants/index'
import useStore from 'store/index'
import s from './index.module.less'

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onOkCb?: (arg: IVolunteerData) => void
  value?: IVolunteerData
}

const GkMsg: React.FC<IProps> = ({ onOkCb, value }) => {
  const { state } = useStore()
  const [baseInfo, setBaseInfo] = useState<IVolunteerData>({
    isSelectCourse: false,
    ...VOLUNTEER_DEFAULTVALUE
  })

  const canSubmit = useMemo(() => {
    const { gkProvince, gkYear, isSelectCourse, gkTotalScore, rank, userRank } = baseInfo
    const actualRank = userRank || rank
    return (
      gkProvince &&
      gkYear &&
      (!isSelectCourse || checkCourse(baseInfo)) &&
      (!gkTotalScore || actualRank)
    )
  }, [baseInfo])

  const handleSave = async () => {
    addRlog('click', {
      button_name: '高考信息保存并进入下一步'
    })
    if (onOkCb) onOkCb(baseInfo)
  }

  const handleBaseInfo = (key, val) => {
    if (key === 'subjectSelect') {
      setBaseInfo({
        ...baseInfo,
        ...val,
        rank: undefined,
        userRank: undefined
      })
      return
    }
    setBaseInfo((prev) => ({
      ...prev,
      rank: undefined,
      userRank: undefined,
      [key]: val
    }))
  }

  useEffect(() => {
    if (value) {
      setBaseInfo(value)
    }
  }, [value])

  useEffect(() => {
    if (state.userInfo?.gkYear) {
      setBaseInfo({
        ...baseInfo,
        gkYear: state.userInfo?.gkYear
      })
    }
  }, [state.userInfo?.gkYear])

  const subjectInfo = useMemo(() => {
    const {
      isSelectCourse,
      volunteerTemplateFirstDto,
      volunteerTemplateSecondDto,
      volunteerTemplateThirdDto
    } = baseInfo
    return {
      isSelectCourse,
      volunteerTemplateFirstDto,
      volunteerTemplateSecondDto,
      volunteerTemplateThirdDto
    }
  }, [baseInfo])

  const renderFooter = () => {
    return (
      <div className={s.flexCenter}>
        <button
          type="button"
          onClick={handleSave}
          className={`${s.okBtn} ${!canSubmit ? s.canClick : ''}`}
          disabled={!canSubmit}
        >
          下一步
        </button>
      </div>
    )
  }

  return (
    <section className={s.gkMsg}>
      <header>高考信息</header>
      <section className={s.content}>
        <GkProvince
          selectWidth={376}
          value={baseInfo.gkProvince}
          onChange={(e) => handleBaseInfo('gkProvince', e)}
        />
        <GkYear
          selectWidth={376}
          value={baseInfo.gkYear}
          onChange={(e) => handleBaseInfo('gkYear', e)}
        />
        <SubjectSelect
          value={subjectInfo}
          onChange={(e) => handleBaseInfo('subjectSelect', e)}
          gkProvince={baseInfo.gkProvince as string}
          gkYear={baseInfo.gkYear as unknown as number}
        />
        <GkTotalScore
          value={baseInfo.gkTotalScore}
          gkProvince={baseInfo.gkProvince as string}
          onChange={(e) => handleBaseInfo('gkTotalScore', e)}
          contentWidth={376}
        />
        <GkRank
          data={baseInfo}
          value={baseInfo?.userRank || baseInfo?.rank}
          onChange={setBaseInfo}
          contentWidth={376}
        />
      </section>
      {renderFooter()}
    </section>
  )
}

GkMsg.defaultProps = {
  onOkCb: undefined,
  value: undefined
}

export default GkMsg
