export const bannerList = [
  {
    id: 'home',
    name: '首页'
  },
  {
    id: 'volunteer',
    name: '填志愿'
  },
  {
    id: 'searchSchool',
    name: '查学校'
  },
  {
    id: 'searchProfession',
    name: '查专业'
  },
  {
    id: 'evaluation',
    name: '做测评'
  },
  {
    id: 'studyPlan',
    name: '升学讲堂'
  },
  // {
  //   id: 'volunteerWiki',
  //   name: '升学百科'
  // },
  {
    id: 'volunteerCard',
    name: '升学规划卡'
  }
  // {
  //   id: 'volunteer1v1',
  //   name: '1v1填报'
  // }
]

export const volunteerTypeList = [
  {
    id: 'collegesVolunteer',
    name: '院校优先填报'
  },
  {
    id: 'professionalVolunteer',
    name: '专业优先填报'
  },
  {
    id: 'probabilityVolunteer',
    name: '录取概率测算'
  }
]
