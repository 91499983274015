/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Input, Radio, message } from 'antd'
import useStore from 'store'
import Address from 'components/Address'
import SelectSchool from 'components/SelectSchool'
import { addRlog } from 'utils/util'
import { SEX_TYPE } from 'constants/index'
import { IBaseinfo } from 'servers/percenter/interface'
import { savePersonInfo, updatePersonInfo, getPersonInfo } from 'servers/percenter'
import s from './index.module.less'

interface IProps {
  onOkCb?: (params?: IBaseinfo) => void
  onCancelCb?: (params?: IBaseinfo) => void
  value?: IBaseinfo | null // 个人信息回显
  toPrev: () => void
  toNext: () => void
}

const PerBaseMsg: React.FC<IProps> = ({ onOkCb, onCancelCb, value, toPrev, toNext }) => {
  const [baseInfo, setBaseInfo] = useState<IBaseinfo>({})
  const { state, dispatch } = useStore()

  const checkSchoolIsLegal = () => {
    const school = baseInfo.school || ''
    if (school?.length) {
      if (school?.length < 4) {
        message.error('请输入就读中学全称！')
        return false
      }
      if (school?.length > 20) {
        message.error('学校输入字符过长')
        return false
      }
    }
    return true
  }

  const handleBaseInfo = (key, val) => {
    if (key === 'address') {
      setBaseInfo({
        ...baseInfo,
        ...val
      })
    } else {
      setBaseInfo({
        ...baseInfo,
        [key]: val
      })
    }
  }

  useEffect(() => {
    if (value) {
      setBaseInfo({ ...value })
    }
  }, [value])

  useEffect(() => {
    if (state.userInfo) {
      setBaseInfo({ ...state.userInfo })
    }
  }, [state.userInfo])

  const handlePrev = () => {
    toPrev()
    if (onCancelCb) onCancelCb(baseInfo)
  }

  const handleSave = async () => {
    if (!checkSchoolIsLegal()) return
    addRlog('click', {
      button_name: '个人信息弹窗完成按钮'
    })
    const res = state.personinfoExist
      ? await updatePersonInfo(baseInfo)
      : await savePersonInfo(baseInfo)
    if (res.code === 0) {
      if (onOkCb) onOkCb(baseInfo)
      toNext()
      const personRes = await getPersonInfo()
      if (personRes.code === 0) {
        dispatch({ type: 'updateUserInfo', payload: personRes.data })
      }
      dispatch({ type: 'updatePersoninfoExist', payload: true })
    } else {
      message.error(res.description)
    }
  }

  const renderFooter = () => {
    return (
      <div className={s.flexCenter}>
        <button type="button" onClick={handlePrev} className={`${s.okBtn} ${s.prevBtn}`}>
          上一步
        </button>
        <button type="button" onClick={handleSave} className={s.okBtn}>
          完成
        </button>
      </div>
    )
  }

  return (
    <section className={s.perBaseMsg}>
      <header>基本信息</header>
      <section className={s.content}>
        <ul className={s.baseMsg}>
          <li>
            <span className={s.label}>所在地区</span>
            <Address width={120} value={baseInfo} onChange={(v) => handleBaseInfo('address', v)} />
          </li>
          <li>
            <span className={s.label}>学校</span>
            <SelectSchool
              width={376}
              value={baseInfo.school}
              city={baseInfo.county || baseInfo.city || baseInfo.province}
              onSelectedSchoolChange={(v) => handleBaseInfo('school', v)}
            />
          </li>
          <li>
            <span className={s.label}>姓名</span>
            <Input
              className={s.baseStyle}
              value={baseInfo?.name}
              maxLength={20}
              onChange={(e) => handleBaseInfo('name', e.target.value)}
            />
          </li>
          <li>
            <span className={s.label}>性别</span>
            <Radio.Group
              value={baseInfo?.sex}
              onChange={(e) => handleBaseInfo('sex', e.target.value)}
            >
              {SEX_TYPE.map((v) => (
                <Radio value={v.id} key={v.id}>
                  {v.name}
                </Radio>
              ))}
            </Radio.Group>
            <p className={s.tips}>性别可能影响专业推荐准确度，请如实选择</p>
          </li>
        </ul>
      </section>
      {renderFooter()}
    </section>
  )
}

PerBaseMsg.defaultProps = {
  onOkCb: undefined,
  onCancelCb: undefined,
  value: null
}

export default PerBaseMsg
