import axios from 'axios'
import { message } from 'antd'

interface Iconfig {
  method?: string
  params?: object | any
}

// 支付相关接口，请求默认配置覆盖
const PAY_BASE_URL = {
  dev: '//ke.ydshengxue.com',
  dev2: '//ke.ydshengxue.com',
  dev3: '//ke.ydshengxue.com',
  pre: '//ke.ydshengxue.com',
  prod: '//ke.ydshengxue.com'
}[process.env.REACT_APP_ENV || 'dev']

export const PAY_BASE_URL_PARAMS = {
  baseURL: PAY_BASE_URL
}

const BASE_CMS_URL = (id: string) => `https://c.youdao.com/volunteer/${id}.json?time=${new Date()}`

export const CMS_URL = {
  test: BASE_CMS_URL('volunteerTest'),
  dev: BASE_CMS_URL('volunteerTest'),
  dev2: BASE_CMS_URL('volunteerTest'),
  dev3: BASE_CMS_URL('volunteerTest'),
  pre: BASE_CMS_URL('volunteer'),
  prod: BASE_CMS_URL('volunteer')
}[process.env.REACT_APP_ENV || 'dev']

export const COURSE_ID = {
  dev: 165295,
  dev2: 165295,
  dev3: 165295,
  pre: 159421,
  prod: 159421
}[process.env.REACT_APP_ENV || 'dev']

// 系统内接口地址
export const BASE_URL = {
  dev: '//hikari-wallnut-gateway-test.inner.ydshengxue.com',
  // dev: '//hikari-wallnut-gateway.ydshengxue.com',
  dev2: '//hikari-wallnut-gateway-test-2.inner.ydshengxue.com',
  dev3: '//hikari-wallnut-gateway.inner.ydshengxue.com',
  pre: '//hikari-wallnut-gateway-pre.inner.ydshengxue.com',
  prod: '//hikari-wallnut-gateway.ydshengxue.com'
}[process.env.REACT_APP_ENV || 'dev']

// 码换课相关url
export const COURSENODE_URL = {
  dev: 'https://ke-wap-test.inner.ydshengxue.com',
  dev2: 'https://ke-wap-test.inner.ydshengxue.com',
  dev3: 'https://ke-wap-test.inner.ydshengxue.com',
  pre: 'https://ke-wap-pre.inner.ydshengxue.com',
  prod: 'https://ke-wap.ydshengxue.com'
}[process.env.REACT_APP_ENV || 'dev']

// 网页url
export const WEB_URL = {
  dev: 'https://ydzy-test.inner.ydshengxue.com/',
  dev2: 'https://ydzy-test2.inner.ydshengxue.com/',
  dev3: 'https://ydzy-test3.inner.ydshengxue.com/',
  pre: 'https://ydzy-pre.inner.ydshengxue.com/',
  prod: 'https://ydzy.ydshengxue.com/'
}[process.env.REACT_APP_ENV || 'dev']

export const REDEEMCODE_URL = (query) => {
  return `${COURSENODE_URL}/wap/pay/redeemCode/input?type=${query}`
}

// check 年份一分一段表等是否变化
const beforeResponse = (provinceYearConfig: {}) => {
  if (!provinceYearConfig) return
  document
    .getElementById('BeforeResponseDom')
    ?.setAttribute('beforeResponseData', JSON.stringify(provinceYearConfig))
}

const options = {
  baseURL: BASE_URL,
  timeout: 15000,
  withCredentials: true
}

const req = axios.create(options)

/**
 * 请求拦截器成功
 * @param config
 */
const reqOnFulfilled = (config: Iconfig) => {
  // 过滤请求参数(过滤null，undefined和空串)
  const filteredParams = {}
  Object.keys(config.params || {}).forEach((key) => {
    if (![null, undefined, ''].includes(config.params[key])) {
      filteredParams[key] = config.params[key]
    }
  })
  config.params = { ...filteredParams } // eslint-disable-line
  return config
}

/**
 * 请求拦截器失败
 * @param error
 */
const reqOnRejected = (error) => Promise.reject(error)

/**
 * 响应拦截器成功
 * @param data
 */
const resOnFulfilled = ({ data = { provinceYearConfig: {} } }) => {
  beforeResponse(data.provinceYearConfig)
  return data
}

/**
 * 失败拦截器失败，区分code 0成功 10未登录
 * @param error
 */
const resOnRejected = (error) => {
  if ([401, 403].includes(error?.response?.status)) {
    message.error('未登录或权限不足')
    return {}
  }
  if ([500].includes(error?.response?.status)) {
    message.error('请求失败，请重试')
    return {}
  }
  return Promise.reject(error)
}

req.interceptors.request.use(reqOnFulfilled, reqOnRejected)

req.interceptors.response.use(resOnFulfilled, resOnRejected)

export default req
