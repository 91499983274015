import qs from 'querystring'
import req from 'utils/request'
import { IBaseinfo } from './interface'

export const savePersonInfo = (params: IBaseinfo): Promise<any> =>
  req.post('college/personinfo/save', params)

export const updatePersonInfo = (params: IBaseinfo): Promise<any> =>
  req.post('college/personinfo/update', params)

export const getPersonInfo = (): Promise<any> => req.get('college/personinfo/query')

export const getVolunteerTable = (params): Promise<any> =>
  req.get(`college/table/list?${qs.stringify(params)}`)

export const getTableDetail = (id: string): Promise<any> => req.get(`college/table/query/${id}`)

export const updateTableInfo = (params): Promise<any> => req.post('college/table/update', params)

export const deleteTableInfo = (id): Promise<any> => req.post(`college/table/delete/${id}`)

export const downLoad = (id: string): Promise<any> => req.get(`college/table/export/${id}`)
