import React from 'react'
import ReactDOM from 'react-dom'
import { GlobalProvider } from 'store'
import App from './pages/App'
import './static/fonts/first/first.css'
import { getQueryString, initEruda } from './utils/util'

initEruda()

// rlog 相关
/* eslint no-underscore-dangle: 0 */
window._rlog = window._rlog || []
if (getQueryString('port') !== 'pad') {
  window._rlog.push(['_setAccount', 19])
  window._rlog.push(['_setSecret', '241e4781c6bc4053a4bfc3701f79f402'])
} else {
  window._rlog.push(['_setAccount', 60])
  window._rlog.push(['_setSecret', 'b5a230d9b0a04eaf863dfa5f2903a3e7'])
}

const Root: React.FC = () => {
  return (
    <GlobalProvider>
      <App />
    </GlobalProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
)
