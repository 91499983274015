import req from 'utils/request'
import qs from 'querystring'
import { RES_TYPES } from '../commonInterface'
import { IGlobalSearch } from './interface'

// 院校优先和专业优先的模糊搜索
export const querySchoolList = (name): Promise<any> => req.get(`college/school/query?name=${name}`)

// 查学校的模糊搜索
export const schoolQueryList = (name): Promise<any> =>
  req.get(`college/school/querySchool?keyword=${name}`)

export const queryGlobalSearch = (params): RES_TYPES<IGlobalSearch> =>
  req.get(`college/search/globalSearch?${qs.stringify(params)}`)

// 根据分数获取排名接口
export const volunteerRank = (
  data
): RES_TYPES<{ ranking: number; rankRange: string; scoreRankTableYear: number }> =>
  req.post('college/volunteer/rank', data)
