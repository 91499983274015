import React, { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from 'components/PrivateRoute'
import Header from 'components/Header'
import Banner from 'components/Banner'
import Footer from 'components/Footer'
import BeforeResponseDom from 'components/BeforeResponseDom'
import RankModal from 'components/RankModal'
import { CMSDATA, getVolunteer, volunteerLocation } from 'servers/home'
import useStore from 'store'
import Feedback from 'components/Feedback'
import { getQueryString } from 'utils/util'

const Home = lazy(() => import('./Home'))
const PerCenter = lazy(() => import('./PerCenter'))
const CollegesVolunteer = lazy(() => import('./CollegesVolunteer'))
const ProfessionalVolunteer = lazy(() => import('./ProfessionalVolunteer'))
const ProbabilityVolunteer = lazy(() => import('./ProbabilityVolunteer'))
const IntentionTable = lazy(() => import('./IntentionTable'))
const VolunteerCard = lazy(() => import('./VolunteerCard'))
const VolunteerOrder = lazy(() => import('./VolunteerOrder'))
const EvaluationEntrance = lazy(() => import('./EvaluationEntrance'))
const EvaluationDetail = lazy(() => import('./EvaluationDetail'))
const EvaluationGround = lazy(() => import('./EvaluationGround'))
const SearchProfession = lazy(() => import('./SearchProfession'))
const SearchProfessionDetail = lazy(() => import('./SearchProfessionDetail'))
const SearchSchool = lazy(() => import('./SearchSchool'))
const SearchSchoolDetail = lazy(() => import('./SearchSchoolDetail'))
const SearchResult = lazy(() => import('./SearchResult'))
const MoreDetail = lazy(() => import('./MoreDetail'))
// pad个人信息部分路由
const PadPerBaseMsg = lazy(() => import('./PadPerCenter/BaseMsg'))
const PadPerVolunteerMsg = lazy(() => import('./PadPerCenter/VolunteerMsg'))
const PadPerVolunteerForm = lazy(() => import('./PadPerCenter/VolunteerForm'))
const PadPerEvaluation = lazy(() => import('./PadPerCenter/Evaluation'))
// GPT 项目
// const VolunteerWiki = lazy(() => import('./VolunteerWiki'))
// const VolunteerOneOne = lazy(() => import('./VolunteerOneOne'))

function App() {
  const { state, dispatch } = useStore()

  const getVolunteerFun = () => {
    getVolunteer().then((res) => {
      if (res.code === 0) {
        dispatch({ type: 'updateVolunteer', payload: res.data })
      }
    })
  }

  const getVolunteerLocationFun = async () => {
    const res = await volunteerLocation()
    if (res.code === 0) dispatch({ type: 'updateVolunteer', payload: res.data })
  }

  useEffect(() => {
    CMSDATA().then((res) => {
      if (res.status === 200) {
        dispatch({ type: 'updateCmsData', payload: res.data })
      }
    })
  }, [])

  useEffect(() => {
    if (state.userInfo?.id) {
      getVolunteerFun()
    }
  }, [state.userInfo?.id])

  useEffect(() => {
    if (state.volunteerData?.gkProvince) {
      getVolunteerLocationFun()
    }
  }, [state.volunteerData?.gkProvince])

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<div />}>
          {getQueryString('port') !== 'pad' && !getQueryString('hiddeBanner') && (
            <>
              <Feedback />
              <Header />
              <Banner />
            </>
          )}
          <Switch>
            <PrivateRoute path="/percenter" component={PerCenter} />
            {getQueryString('port') !== 'pad' ? (
              <PrivateRoute
                path="/collegesVolunteer"
                component={CollegesVolunteer}
                needCheckVolData
              />
            ) : (
              <Route path="/collegesVolunteer" component={CollegesVolunteer} />
            )}
            <PrivateRoute
              path="/professionalVolunteer"
              component={ProfessionalVolunteer}
              needCheckVolData
            />
            {getQueryString('port') !== 'pad' ? (
              <PrivateRoute
                path="/probabilityVolunteer"
                component={ProbabilityVolunteer}
                needCheckVolData
              />
            ) : (
              <Route path="/probabilityVolunteer" component={ProbabilityVolunteer} />
            )}
            <PrivateRoute path="/intentionTable/:id" component={IntentionTable} />
            <PrivateRoute path="/volunteerOrder" component={VolunteerOrder} />
            <Route path="/volunteerCard" component={VolunteerCard} />
            <Route path="/evaluation" component={EvaluationEntrance} />
            <Route path="/evaluationDetail/:type" component={EvaluationDetail} />
            <Route path="/searchProfession" component={SearchProfession} />
            <Route path="/searchProfessionDetail" component={SearchProfessionDetail} />
            <Redirect exact from="/searchSchool/:id" to="/searchSchool/:id/base" />
            <Route path="/searchSchool/:id/:page" component={SearchSchoolDetail} />
            <Route path="/searchSchool" component={SearchSchool} />
            <Route path="/searchResult" component={SearchResult} />
            {getQueryString('port') === 'pad' ? (
              <Route path="/evaluationGround/:type" component={EvaluationGround} />
            ) : (
              <PrivateRoute path="/evaluationGround/:type" component={EvaluationGround} />
            )}
            <Route path="/moreDetail" component={MoreDetail} />
            {/* pad相关路由 */}
            <Route path="/padPerBaseMsg" component={PadPerBaseMsg} />
            <Route path="/padPerVolunteerMsg" component={PadPerVolunteerMsg} />
            <Route path="/padPerVolunteerForm" component={PadPerVolunteerForm} />
            <Route path="/padPerEvaluation" component={PadPerEvaluation} />

            {/* GPT 项目 */}
            {/* {state.disableGptList.includes('volunteerWiki') ? null : ( */}
            {/*  <Route path="/volunteerWiki" component={VolunteerWiki} /> */}
            {/* )} */}
            {/* {state.disableGptList.includes('volunteer1v1') ? null : ( */}
            {/*  <Route path="/volunteer1v1" component={VolunteerOneOne} /> */}
            {/* )} */}

            <Route path="/*" component={Home} />
          </Switch>
          {getQueryString('port') !== 'pad' && !getQueryString('hiddeBanner') && <Footer />}
        </Suspense>
        <RankModal />
      </BrowserRouter>
      <BeforeResponseDom />
    </div>
  )
}

export default App
