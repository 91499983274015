/**
 * 选科
 */

import React, { useState, useEffect } from 'react'
import {
  FIRST_PERFER_SUBJECTS,
  FIRST_RESELECT_SUBJECTS,
  SECOND_SUBJECTS,
  THIRD_SUBJECTS_OBJ,
  FIRST_SUBJECTS,
  RESELECT_SUBJECTS,
  VOLUNTEER_DEFAULTVALUE,
  MODE
} from 'constants/index'
import SelectSubject from 'components/SelectSubject'
import { message, Radio } from 'antd'
import { volunteerExamMode } from 'servers/home'
import { IExamModeParams } from 'servers/home/interface'
import s from './index.module.less'

interface IProps {
  gkProvince: string
  isSelectCourse: boolean
  gkYear: number
  styleType?: 'row' | 'col'
  value?: any
  // eslint-disable-next-line no-unused-vars
  onChange?: (arg: any) => void
  // 是否是pad
  isPad?: boolean
}

const basicObj = {
  biology: false,
  chemistry: false,
  geography: false,
  history: false,
  physics: false,
  politics: false
}

const ElectiveSubjects: React.FC<IProps> = ({
  value,
  isSelectCourse,
  onChange,
  gkProvince,
  gkYear,
  styleType,
  isPad
}) => {
  const [data, setData] = useState({ ...VOLUNTEER_DEFAULTVALUE })
  // 用于没有选项时请求获取当前省份+年份的相应模式
  const [mode, setMode] = useState<number>()
  const [firstPreferVal, setFirstPreferVal] = useState('')
  const [firstReselectVal, setFirstReselectVal] = useState<string[]>([])
  const [secondVal, setSecondVal] = useState<string[]>([])
  const isRow = styleType === 'row'

  const handleChange = (payload, isChange = true) => {
    if (isChange) {
      setData(payload)
      if (onChange) onChange({ ...payload, isChange })
    }
  }

  const handleData = (key, val) => {
    let curData
    // 3+1+2
    if (key === 'volunteerTemplateFirstDto') {
      if (typeof val === 'string') {
        const reselectVal = {}
        Object.keys(data[key]).forEach((k) => {
          if (data[key][k] && RESELECT_SUBJECTS.includes(k)) {
            reselectVal[k] = true
          }
        })
        curData = {
          ...data,
          [key]: {
            ...basicObj,
            ...reselectVal,
            [val]: true
          }
        }
      }
      if (Array.isArray(val)) {
        const payload = {}
        const preferVal = {}
        Object.values(val).forEach((x) => {
          payload[x] = true
        })
        Object.keys(data[key]).forEach((k) => {
          if (data[key][k] && FIRST_SUBJECTS.includes(k)) {
            preferVal[k] = true
          }
        })
        curData = {
          ...data,
          [key]: {
            ...basicObj,
            ...preferVal,
            ...payload
          }
        }
      }
    }
    // 6选3
    if (key === 'volunteerTemplateSecondDto' && Array.isArray(val)) {
      const payload = {}
      Object.values(val).forEach((x) => {
        payload[x] = true
      })
      curData = {
        ...data,
        [key]: {
          ...basicObj,
          technology: false,
          ...payload
        }
      }
    }
    // 文理科
    if (key === 'volunteerTemplateThirdDto') {
      curData = {
        ...data,
        volunteerTemplateThirdDto: {
          subjectType: val
        }
      }
    }
    handleChange(curData)
  }

  const renderFirst = () => {
    return (
      <>
        <div
          className={`${isRow ? s.formList : s.colFormList} `}
          style={isRow ? { alignItems: 'start' } : {}}
        >
          <span className={s.formListLabel}>
            <span className={s.required}>*</span>
            首选科目（2选1）
          </span>
          <div className={s.formListContent}>
            <SelectSubject
              options={FIRST_PERFER_SUBJECTS}
              onChange={(e) => handleData('volunteerTemplateFirstDto', e)}
              value={firstPreferVal}
            />
          </div>
        </div>
        <div
          className={`${isRow ? s.formList : s.colFormList}`}
          style={isRow ? { alignItems: 'start' } : {}}
        >
          <span className={s.formListLabel}>
            <span className={s.required}>*</span>
            再选科目（4选2）
          </span>
          <div className={s.formListContent}>
            <SelectSubject
              options={FIRST_RESELECT_SUBJECTS}
              onChange={(e) => handleData('volunteerTemplateFirstDto', e)}
              notifyTitle="再选科目"
              multiple
              max={2}
              value={firstReselectVal}
            />
          </div>
        </div>
      </>
    )
  }

  const renderSecond = () => {
    return (
      <div
        className={`${isRow ? s.formList : s.colFormList} `}
        style={isRow ? { alignItems: 'start', marginBottom: gkProvince && gkYear ? 18 : 0 } : {}}
      >
        <span className={s.formListLabel}>
          <span className={s.required}>*</span>
          选择科目（{gkProvince === '浙江省' ? '7' : '6'}选3）
        </span>
        <div className={s.formListContent}>
          <SelectSubject
            options={gkProvince === '浙江省' ? SECOND_SUBJECTS : SECOND_SUBJECTS.slice(0, 6)}
            onChange={(e) => handleData('volunteerTemplateSecondDto', e)}
            notifyTitle="科目"
            multiple
            max={3}
            value={secondVal}
            isPad={!!isPad}
          />
        </div>
      </div>
    )
  }

  const renderThird = () => {
    return (
      <div className={`${isRow ? s.formList : s.colFormList} ${isPad ? s.pad : ''}`}>
        <span className={s.formListLabel}>
          <span className={s.required}>*</span>
          科目类型
        </span>
        <div className={`${s.formListContent} ${s.radioContent}`}>
          <Radio.Group
            onChange={(e) => handleData('volunteerTemplateThirdDto', e.target.value)}
            // @ts-ignore
            value={data.volunteerTemplateThirdDto?.subjectType?.toString()}
          >
            {Object.keys(THIRD_SUBJECTS_OBJ).map((k) => (
              <Radio key={k} value={k}>
                {THIRD_SUBJECTS_OBJ[k]}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const { volunteerTemplateFirstDto, volunteerTemplateSecondDto } = data
    if (volunteerTemplateFirstDto) {
      const firstKeys = Object.keys(volunteerTemplateFirstDto)
      if (firstKeys.length) {
        // 首选科目
        const firstPrefer = firstKeys
          .filter((x) => {
            if (volunteerTemplateFirstDto[x] && FIRST_SUBJECTS.includes(x)) {
              return x
            }
            return ''
          })
          .toString()
        setFirstPreferVal(firstPrefer)
        // 再选科目
        const firstReselect = firstKeys
          .filter((x) => {
            if (volunteerTemplateFirstDto[x] && RESELECT_SUBJECTS.includes(x)) {
              return x
            }
            return ''
          })
          .filter((x) => x)
        setFirstReselectVal(firstReselect)
      }
    }
    if (volunteerTemplateSecondDto) {
      // 选择科目
      const secondKeys = Object.keys(volunteerTemplateSecondDto)
      if (secondKeys.length) {
        const second = secondKeys
          .map((x) => {
            if (volunteerTemplateSecondDto[x]) {
              return x
            }
            return ''
          })
          .filter((x) => x)
        setSecondVal(second)
      }
    }
  }, [data])

  const renderDOM = (type) => {
    switch (type) {
      case 'volunteerTemplateFirstDto':
        return renderFirst()
      case 'volunteerTemplateSecondDto':
        return renderSecond()
      case 'volunteerTemplateThirdDto':
        return renderThird()
      default:
        return null
    }
  }

  const queryVolunteer = async () => {
    const params: Partial<IExamModeParams> = {
      gkProvince,
      gkYear
    }
    const res = await volunteerExamMode(params)
    if (res.code === 0) {
      const curMode = res?.data?.find((x) => x?.year === gkYear && x.province === gkProvince)?.mode
      setMode(curMode)
      // 当前模式和新模式不一样则重置参数
      if (!mode || mode !== curMode) {
        handleChange({ ...VOLUNTEER_DEFAULTVALUE, mode: MODE[curMode] }, !value?.templateType)
      }
    } else {
      message.error(res.description)
    }
  }

  useEffect(() => {
    if (value) {
      setData(value)
    }
  }, [value])

  useEffect(() => {
    if (gkProvince && gkYear && isSelectCourse) {
      queryVolunteer()
    }
  }, [isSelectCourse, gkProvince, gkYear])

  useEffect(() => {
    if (gkProvince !== '浙江省' && secondVal.includes('technology')) {
      const val = secondVal.filter((x) => x !== 'technology')
      setSecondVal(val)
      const payload = {}
      Object.values(val).forEach((x) => {
        payload[x] = true
      })
      handleChange({
        ...data,
        volunteerTemplateSecondDto: {
          ...basicObj,
          technology: false,
          ...payload
        }
      })
    }
  }, [gkProvince, secondVal])

  return renderDOM(MODE[mode])
}

ElectiveSubjects.defaultProps = {
  value: undefined,
  onChange: undefined,
  styleType: 'row',
  isPad: false
}

export default ElectiveSubjects
