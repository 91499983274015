/**
 * @description 高考省份
 */

import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import Triangle from 'components/Triangle'
import { specialProvincesData } from 'constants/address'
import s from './index.module.less'

interface IProps {
  value?: string
  disabled?: boolean
  selectWidth?: number
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  useInChat?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange?: (arg: string) => void
}

const GkProvince: React.FC<IProps> = ({ value, disabled, onChange, useInChat, selectWidth }) => {
  const [province, setProvince] = useState<string>()
  const handleChange = (e) => {
    setProvince(e)
    if (onChange) onChange(e)
  }

  useEffect(() => {
    if (value) {
      setProvince(value)
    }
  }, [value])

  return (
    <div className={s.formList}>
      <span className={s.formListLabel}>
        <span className={s.required}>*</span>
        高考省份
      </span>
      <div className={s.formListContent}>
        <Select
          showSearch
          value={province}
          style={{ width: selectWidth }}
          className="ant-sel"
          onChange={handleChange}
          placeholder="请选择高考省份"
          filterOption={(input, option) =>
            // @ts-ignore
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          suffixIcon={<Triangle />}
          disabled={disabled}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {(useInChat
            ? ['河北省', '山东省', '浙江省', '辽宁省', '重庆市']
            : specialProvincesData
          ).map((v) => (
            <Select.Option value={v} key={v}>
              {v}
            </Select.Option>
          ))}
        </Select>
        {province && <p className={`${s.formListTips} ${s.required}`}>高考地区一经确认不可修改</p>}
      </div>
    </div>
  )
}

GkProvince.defaultProps = {
  value: '',
  disabled: false,
  selectWidth: 308,
  onChange: () => {}
}

export default GkProvince
