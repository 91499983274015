import React, { useState, useEffect } from 'react'
import Modal from 'components/Modal'
import useStore from 'store'
import { userRankScoreCheck, updateUserRankSign } from 'servers/home'
import { IrankScoreCheck } from 'servers/home/interface'
import VolunteerModal from 'components/VolunteerModal'
import { useLocation } from 'react-router-dom'
import s from './index.module.less'

interface IProps {
  rankData?: IrankScoreCheck | undefined
}

const RankModal: React.FC<IProps> = ({ rankData }) => {
  const { state, dispatch } = useStore()
  const { pathname } = useLocation()
  const { rankCheckModel } = state
  const [visible, setVisible] = useState(false)
  const [rankDataMsg, setRankDataMsg] = useState<IrankScoreCheck | undefined>(rankData) // 位次偏差提示信息
  const [modalVisible, setModalVisible] = useState(false) // 编辑高考信息

  // 去修改填报信息
  const changeMessage = () => {
    if (state.volunteerData) {
      dispatch({
        type: 'updateRankCheckModel',
        payload: false
      })
      setModalVisible(true)
    }
  }

  // 信息确认无误
  const cancelFun = async () => {
    await updateUserRankSign()
    dispatch({
      type: 'updateRankCheckModel',
      payload: false
    })
  }

  const getUserRank = async () => {
    const { data } = await userRankScoreCheck()
    if (data && !document.getElementById('rankId')) {
      setRankDataMsg(data)
      setVisible(true)
      dispatch({
        type: 'updateRankCheckModel',
        payload: true
      })
    }
  }

  useEffect(() => {
    getUserRank()
  }, [pathname])

  // 是否展示位次确认弹层
  useEffect(() => {
    if (rankCheckModel && !rankDataMsg) {
      getUserRank()
      return
    }
    setVisible(rankCheckModel)
  }, [rankCheckModel])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={cancelFun}
        onOk={changeMessage}
        title=""
        okButtonText="修改信息"
        cancelButtonText="确认无误"
        footer="okAndCancel"
        closable={false}
        maskClosable={false}
      >
        <section className={s.checkContent} id="rankId">
          <header>请确认当前分数位次填写正确</header>
          <p className={s.title}>
            您填写的高考总分{rankDataMsg?.score}分、成绩排名<span>{rankDataMsg?.rank}</span>
            ,与系统当前最新的
            <span>{rankDataMsg?.scoreRankTableYear}年一分一段表</span>中,选科{rankDataMsg?.subject}
            ,分数{rankDataMsg?.score}分,对应的排名区间<span>{rankDataMsg?.rankRange}</span>不相符。
          </p>
          <p className={s.title}>如确认无误，将按照您填写的排名推荐志愿数据～</p>
          <p className={s.tips}>
            注：若系统最新一分一段表的年份，不是您的高考年份，请耐心等待，我们会在官方公布后2~8小时内更新到系统。如您已获得并填写了自己高考年份的最新排名，可选择“确认无误”继续填报。
          </p>
        </section>
      </Modal>
      <VolunteerModal
        visible={modalVisible}
        value={state.volunteerData}
        onCancel={() => {
          window.location.reload()
        }}
      />
    </>
  )
}

RankModal.defaultProps = {
  rankData: undefined
}

export default RankModal
