import req from '../../utils/request'
import {
  EVA_API,
  QUESTION_LIST_RES,
  SUBMIT_DATA,
  HOLLAND_SUBMIT_RES,
  REPORT_LIST_RES
} from './indterface'

/**
 * 霍兰德题目列表
 */
export const hollandListQuestion = (): EVA_API<QUESTION_LIST_RES[]> =>
  req.get('wallnut/holland/listQuestion')

/**
 * 提交霍兰德普通题目作答结果
 * @param data
 */
export const hollandSubmit = (data: SUBMIT_DATA): EVA_API<HOLLAND_SUBMIT_RES[]> =>
  req.post('wallnut/holland/submit', data)

/**
 * 提交霍兰德补充题目作答结果
 * @param data
 */
export const hollandSubmitSupplementQuestion = (data: {
  answerId: number
  // 花费时间
  costTime: number
  // 属性选择属性
  types: string[]
}): EVA_API<number> => req.post('wallnut/holland/submitSupplementQuestion', data)

/**
 * MBTI题目列表
 */
export const mbtiListQuestion = (): EVA_API<QUESTION_LIST_RES[]> =>
  req.get('wallnut/mbti/listQuestion')

/**
 * 提交霍兰德普通题目作答结果
 * @param data
 */
export const mbtiSubmit = (data: SUBMIT_DATA): EVA_API<number> =>
  req.post('wallnut/mbti/submit', data)

/**
 * 报告列表接口
 */
export const listReportBaseInfo = (): EVA_API<REPORT_LIST_RES[]> =>
  req.get('wallnut/common/listReportBaseInfo')

/**
 * 存储报告地址
 * @param data
 */
export const savePdfUrl = (data: { answerId: number; reportUrl: string }): EVA_API<any> =>
  req.post('wallnut/common/updateReportUrl', data)
