/* eslint-disable no-case-declarations */

import React, { useState, useEffect, useMemo } from 'react'
import Modal from 'components/Modal'
import useStore from 'store'
import { addRlog, checkType, getQueryString } from 'utils/util'
import { useLocation, useHistory } from 'react-router-dom'
import { FIRST_SUBJECTS, RESELECT_SUBJECTS, MODE } from 'constants/index'
import { IVolunteerData } from 'store/interface'
import { message } from 'antd'
import { getVolunteer, volunteerUpdate, volunteerSave } from 'servers/home'
import GkProvince from 'components/GkProvince'
import GkYear from 'components/GkYear'
import GkTotalScore from 'components/GkTotalScore'
import GkRank from 'components/GkRank'
import ElectiveSubjects from 'components/ElectiveSubjects'
import s from './index.module.less'

interface IProps {
  visible: boolean
  value?: IVolunteerData | null
  onCancel: () => void
}

const VolunteerModal: React.FC<IProps> = ({ visible, value, onCancel }) => {
  const defaultValue = {
    gkProvince: undefined,
    gkTotalScore: undefined,
    gkYear: undefined,
    isSelectCourse: undefined,
    volunteerTemplateFirstDto: {
      biology: false,
      chemistry: false,
      geography: false,
      history: false,
      physics: false,
      politics: false
    },
    volunteerTemplateSecondDto: {
      biology: false,
      chemistry: false,
      geography: false,
      history: false,
      physics: false,
      politics: false,
      technology: false
    },
    volunteerTemplateThirdDto: {
      subjectType: undefined
    },
    mode: undefined,
    templateType: undefined
  }
  const { state, dispatch } = useStore()
  const [template, setTemplate] = useState<string>('')
  const [data, setData] = useState<IVolunteerData>(value || defaultValue)
  const location = useLocation()

  const useHis = useHistory()

  const getVolunteerFun = async () => {
    getVolunteer().then((res) => {
      if (res.code === 0) {
        dispatch({ type: 'updateVolunteer', payload: res.data })
        if (res.data?.isSelectCourse && res.data.gkTotalScore) {
          state.afterMsgCb?.()
        }
        dispatch({ type: 'updateMsgCb', payload: null })
      }
    })
  }

  const handleOk = async () => {
    addRlog('click', {
      button_name: '修改/维护成绩弹窗确认'
    })
    const { gkProvince, gkTotalScore, gkYear, isSelectCourse, userRank } = data
    const params = {
      gkProvince,
      gkTotalScore,
      gkYear,
      isSelectCourse,
      rank: userRank,
      isRealVolunteer: state.cmsData.isRealVolunteer,
      [template]: data[template]
    }
    const res = await (value ? volunteerUpdate(params) : volunteerSave(params))
    if (res?.code === 0) {
      await getVolunteerFun()
      // 如果不符合条件 则刷新页面
      if (res.data) {
        window.location.reload()
      }
      // 清空localStorage内存储的购物车信息
      localStorage.removeItem(`${state.volunteerData?.gkProvince}${state.userInfo?.id}`)
      message.success('保存成功')
      // 填志愿下三个页面刷新
      const { pathname } = location
      if (pathname.includes('Volunteer')) {
        // pad 下刷新需要去掉路由的showEdit
        if (getQueryString('port') === 'pad') {
          useHis.replace({
            pathname: `${pathname}`,
            search: `?type=1&port=pad`
          })
          window.location.reload()
        } else {
          window.location.reload()
        }
      } else {
        onCancel()
      }
    } else {
      message.error(res.description || '保存失败')
    }
  }
  const handleData = (key, val) => {
    if (key === 'electiveSubjects') {
      if (!value?.templateType || val.isChange) {
        setData({
          ...data,
          ...val,
          rank: undefined,
          userRank: undefined,
          isSelectCourse: true
        })
      }
      setTemplate(data.mode || MODE[data.templateType] || '')
      return
    }
    if (key === 'gkProvince') {
      ;[
        'volunteerTemplateFirstDto',
        'volunteerTemplateSecondDto',
        'volunteerTemplateThirdDto'
      ].forEach((item) => {
        if (item !== 'volunteerTemplateThirdDto') {
          Object.keys(data[item]).forEach((v) => {
            data[item][v] = false
          })
        } else if (data.volunteerTemplateThirdDto) {
          data.volunteerTemplateThirdDto.subjectType = undefined
        }
      })
    }
    setData((prev) => ({
      ...prev,
      rank: undefined,
      userRank: undefined,
      [key]: val
    }))
  }

  useEffect(() => {
    if (value) {
      setData(value)
      setTemplate(checkType(value))
    }
  }, [value])

  useEffect(() => {
    if (visible) {
      addRlog('dialog', {
        dialog_name: '修改/维护高考信息弹窗'
      })
    } else {
      if (getQueryString('showEdit') === 'double' || getQueryString('showEdit') === 'sigle') return
      addRlog('dialog', {
        dialog_name: '修改/维护高考信息弹窗关闭'
      })
    }
  }, [visible])

  const getBtnDisabled = () => {
    let required = true
    const {
      volunteerTemplateFirstDto,
      volunteerTemplateSecondDto,
      volunteerTemplateThirdDto,
      gkTotalScore,
      rank,
      userRank
    } = data
    switch (template) {
      case 'volunteerTemplateFirstDto':
        const firstRes = Object.keys(volunteerTemplateFirstDto as {}).filter(
          (k) => (volunteerTemplateFirstDto as {})[k]
        )
        const first = firstRes.filter((x) => FIRST_SUBJECTS.includes(x))
        const reselect = firstRes.filter((x) => RESELECT_SUBJECTS.includes(x))
        if (first.length !== 1 || reselect.length !== 2) {
          required = false
        } else {
          required = true
        }
        break
      case 'volunteerTemplateSecondDto':
        const secondRes = Object.keys(volunteerTemplateSecondDto as {}).filter(
          (k) => (volunteerTemplateSecondDto as {})[k]
        )
        if (secondRes.length !== 3) {
          required = false
        } else {
          required = true
        }
        break
      case 'volunteerTemplateThirdDto':
        if (!volunteerTemplateThirdDto?.subjectType) {
          required = false
        } else {
          required = true
        }
        break
      default:
        required = false
    }
    const actualRank = userRank || rank
    if (gkTotalScore && !actualRank) required = false
    return data.gkProvince && data.gkTotalScore && required
  }

  const electiveSubjectsData = useMemo(() => {
    const {
      isSelectCourse,
      volunteerTemplateFirstDto,
      volunteerTemplateSecondDto,
      volunteerTemplateThirdDto,
      templateType
    } = data
    if (isSelectCourse) {
      return {
        volunteerTemplateFirstDto,
        volunteerTemplateSecondDto,
        volunteerTemplateThirdDto,
        templateType
      }
    }
    return null
  }, [data])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          addRlog('click', {
            button_name: '修改维护成绩弹窗关闭'
          })
          onCancel()
        }}
        title="模拟志愿填报"
        titleSize="large"
        subTitle="输入成绩信息，为您精准推荐"
        okButtonDisabled={!getBtnDisabled()}
        onOk={handleOk}
        footer="ok"
        okButtonStyle={{ fontSize: 16 }}
      >
        <GkProvince
          value={data.gkProvince}
          onChange={(e) => handleData('gkProvince', e)}
          disabled={!!value}
        />
        <GkYear value={data.gkYear} onChange={(e) => handleData('gkYear', e)} disabled={!!value} />
        {data.gkProvince && data.gkYear ? (
          <>
            <ElectiveSubjects
              isSelectCourse
              gkProvince={data.gkProvince}
              gkYear={Number(data.gkYear)}
              value={electiveSubjectsData}
              onChange={(e) => handleData('electiveSubjects', e)}
            />
            <GkTotalScore
              gkProvince={data.gkProvince}
              value={data.gkTotalScore}
              required
              onChange={(e) => handleData('gkTotalScore', e)}
            />
            <GkRank data={data} required value={data?.userRank || data?.rank} onChange={setData} />
          </>
        ) : (
          <p className={s.tips}>依据历年数据进行推荐，每年6月更新最新招生计划，敬请关注</p>
        )}
      </Modal>
    </>
  )
}

VolunteerModal.defaultProps = {
  value: null
}

export default VolunteerModal
