import React from 'react'
import feedbackImg from 'static/images/feedback.svg'
import s from './index.module.less'

const Feedback = () => {
  return (
    <button
      className={s.feedback}
      type="button"
      onClick={() => window.open('https://c.youdao.com/article/index.html?pid=83591#/')}
    >
      <img src={feedbackImg} alt="" />
      <span>意见反馈</span>
    </button>
  )
}

export default Feedback
