import React, { useEffect, useState } from 'react'
import useStore from 'store'
import Modal from 'components/Modal'

import { addCarTable } from 'servers/VolunteerCar'
import { message } from 'antd'
import s from './index.module.less'

const BeforeResponseDom: React.FC<{}> = () => {
  const { state, dispatch } = useStore()
  const { volunteerData, userInfo } = state
  const [modalVisible, setModalVisible] = useState(false)
  // const [errorText, setErrorText] = useState('') // 定制化文案
  const [carIds, setIds] = useState<string[]>([])

  const getIds = (volunteerCarData) => {
    const ids: string[] = []
    if (volunteerData?.hasSchoolGroup || volunteerData?.hasGroup)
      volunteerCarData.forEach((item) =>
        item.recordList?.forEach((m) => m.items?.forEach((v) => ids.push(v?.id || '')))
      )
    if (volunteerData?.isSigle) {
      volunteerCarData.forEach((item) => item.recordList?.forEach((v) => ids.push(v?.id || '')))
    }
    setIds(ids)
  }

  const save = async () => {
    const config = JSON.parse(
      localStorage.getItem(`${volunteerData?.gkProvince}${userInfo?.id}config`) || '{}'
    )
    const { enrollmentPlanYear, scoreRankTableYear, latestDataYear, majorPackageYear } = config
    const res = await addCarTable({
      source: 'COLLEGE',
      ids: carIds,
      admissionYear: latestDataYear,
      enrollmentPlanYear,
      scoreRankTableYear,
      majorPackageYear,
      isAuto: true
    })
    if (res?.data?.refresh && state?.volunteerData && state?.userInfo?.id) {
      message.warning('当前系统已更新最新数据，历史数据已失效，请重新选择意向志愿')
      // 数据源变更，清除本地填报信息
      localStorage.removeItem(`${state?.volunteerData?.gkProvince}${state?.userInfo?.id}`)
    }
  }

  const handleOk = async () => {
    // 保存
    await save()
    localStorage.removeItem(`${volunteerData?.gkProvince}${userInfo?.id}`)
    localStorage.removeItem(`${volunteerData?.gkProvince}${userInfo?.id}config`)
    setModalVisible(false)
    setTimeout(() => window.location.reload(), 3000)
  }

  useEffect(() => {
    const ResponseConfig = document
      .getElementById('BeforeResponseDom')
      ?.getAttribute('beforeResponseData')
    const config = localStorage.getItem(`${volunteerData?.gkProvince}${userInfo?.id}config`) || ''
    const localData = localStorage.getItem(`${volunteerData?.gkProvince}${userInfo?.id}`) || ''
    if (localData) getIds(JSON.parse(localData))
    // 老购物车有数据数据直接删除
    if ((config === '{}' || !config) && localData) {
      dispatch({ type: 'updateVolunteerCar', payload: [] })
      localStorage.removeItem(`${volunteerData?.gkProvince}${userInfo?.id}`)
      window.location.reload()
      return
    }
    if (!localData || ResponseConfig === '{}' || !ResponseConfig) return
    const { scoreRankTableYear, latestDataYear, enrollmentPlanYear } = JSON.parse(ResponseConfig)
    // 填报规则和招生计划发生变化（这两个数据一起更新的）、专业录取数据更新、一分一段表更新 增加弹窗提示
    if (!(volunteerData?.hasSchoolGroup || volunteerData?.hasGroup || volunteerData?.isSigle)) {
      return
    }
    if (JSON.parse(config).scoreRankTableYear !== scoreRankTableYear) {
      // setErrorText(`${scoreRankTableYear}年成绩分段表`)
      setModalVisible(true)
      return
    }
    if (JSON.parse(config).latestDataYear !== latestDataYear) {
      // setErrorText(`${latestDataYear}年专业录取信息`)
      setModalVisible(true)
      return
    }
    if (JSON.parse(config).enrollmentPlanYear !== enrollmentPlanYear) {
      // setErrorText(`${enrollmentPlanYear}年招生计划`)
      setModalVisible(true)
    }
  }, [
    document.getElementById('BeforeResponseDom')?.getAttribute('beforeResponseData'),
    volunteerData?.hasSchoolGroup,
    volunteerData?.hasGroup,
    volunteerData?.isSigle
  ])
  return (
    <div id="BeforeResponseDom">
      <Modal
        title="温馨提示"
        footer="ok"
        okButtonText="我知道了"
        wrapClassName={s.modal}
        wrapContentClassName={s.wrapContent}
        visible={modalVisible}
        onCancel={handleOk}
        onOk={handleOk}
      >
        <p className={s.text}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统已更新最新数据，即将结合最新数据为您推荐适合的院校专业，建议您按照最新推荐重新选择志愿。
        </p>
        {!!carIds.length && (
          <p className={s.text}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;历史已选择的志愿已自动保存在个人中心-我的志愿表，可自行下载查看。
          </p>
        )}
      </Modal>
    </div>
  )
}

export default BeforeResponseDom
