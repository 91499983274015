import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import MapLoader from 'utils/mapLoader'
import { debounce } from 'lodash-es'

const { Option } = Select

interface IProps {
  width?: number
  value?: string
  city?: string
  onSelectedSchoolChange: Function
}
interface IselectValue {
  name?: string
  id: number
}

const SelectSchool: React.FC<IProps> = ({ width, value, city, onSelectedSchoolChange }) => {
  const [selectSchoolList, setSelectSchoolList] = useState<IselectValue[]>([])
  const [selectValue, setSelectValue] = useState<string | undefined>(undefined)

  const handleChange = (val) => {
    setSelectValue(val)
    onSelectedSchoolChange(val || '')
  }

  const handleSearch = (val) => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const placeSearch = new AMap.PlaceSearch({
      // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
      city,
      citylimit: true,
      type: '学校|高中',
      pageSize: 100,
      extensions: 'all'
    })

    // eslint-disable-next-line func-names
    placeSearch.search(val, function (status, result) {
      // 查询成功时，result即对应匹配的POI信息
      if (result.info === 'OK') {
        // 包含已输入的搜索值val
        // 包含【小学、幼儿园、幼稚园、附小】的数据不显示
        // 包含【大学、学院】且不含【附属】or【附中】字眼的数据不显示
        const res = result.poiList.pois.filter(
          (x) =>
            (x.name.includes(val) &&
              !x.name.includes('小学') &&
              !x.name.includes('幼儿园') &&
              !x.name.includes('幼稚园') &&
              !x.name.includes('附小')) ||
            x.name.includes('大学') ||
            (x.name.includes('学院') && (x.name.includes('附属') || x.name.includes('附中')))
        )
        setSelectSchoolList(res)
      } else {
        setSelectSchoolList([])
      }
    })
  }

  const handleFocus = () => {
    handleSearch('')
  }

  // blur也改变值，支持用户自定义输入的学校名
  const handleBlur = (e) => {
    const val = e.target.value
    if (val) {
      setSelectValue(val)
      onSelectedSchoolChange(val || '')
    }
  }

  useEffect(() => {
    // @ts-ignore
    new MapLoader({}).load().then(() => {
      console.log('load end')
      /* eslint-disable */
      // @ts-ignore
      const map = new window.AMap.Map('map-container', {
        resizeEnable: true
      })
      map.plugin('AMap.PlaceSearch')
    })
  }, [])

  useEffect(() => {
    if (value) {
      setSelectValue(value)
    }
  }, [value])

  return (
    <>
      <Select
        showSearch
        style={{ width }}
        value={selectValue}
        placeholder="请选择"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={debounce(handleSearch, 500)}
        onChange={handleChange}
        notFoundContent={null}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {selectSchoolList.map((v) => (
          // @ts-ignore
          <Option key={v.id} value={v?.name}>
            {v.name}
          </Option>
        ))}
      </Select>
      <div id="map-container" />
    </>
  )
}

SelectSchool.defaultProps = {
  width: 478,
  value: '',
  city: ''
}

export default SelectSchool
