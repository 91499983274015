import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import Modal from 'components/Modal'
import useStore from 'store'
import { addRlog } from 'utils/util'
import { IBaseinfo } from 'servers/percenter/interface'
import { getVolunteer, volunteerSave } from 'servers/home/index'
import { IVolunteerData } from 'store/interface'
import PerBaseMsg from './components/PerBaseMsg'
import GkMsg from './components/GkMsg'
import s from './index.module.less'
import tipsImg from './tips.svg'

type IStep = 1 | 2 | 3

// closeCb 取消回调， okCb 确认省份的回调

const PerCenterModal: React.FC<{ closeCb?: () => void; okCb?: () => void }> = ({
  closeCb,
  okCb
}) => {
  const closeTitle = '信息尚未提交完毕，将会影响志愿\n填报功能使用，确认关闭吗？'
  const [msgVisible, setMsgVisible] = useState(true)
  const [checkVisible, setCheckVisible] = useState(false)
  const [closeVisible, setCloseVisible] = useState(false)
  const [gkMsgData, setGkMsgData] = useState<IVolunteerData>()
  const [baseMsgData, setBaseMsgData] = useState<IBaseinfo>({})
  const [step, setStep] = useState<IStep>(1)
  const { state, dispatch } = useStore()

  const onCancel = () => {
    setCloseVisible(true)
  }

  const onOkCbGk = (params) => {
    setCheckVisible(false)
    setStep(2)
    setGkMsgData(params)
  }

  const onOkCbPer = (params) => {
    setMsgVisible(false)
    addRlog('dialog', {
      dialog_name: '个人信息弹窗关闭按钮'
    })
    setCheckVisible(true)
    setBaseMsgData(params)
  }

  const onCancelCbPer = (params) => {
    setMsgVisible(true)
    setCheckVisible(false)
    setBaseMsgData(params)
  }

  const getVolunteerFun = async () => {
    getVolunteer().then((res) => {
      if (res.code === 0) {
        dispatch({ type: 'updateVolunteer', payload: res.data })
        if (res.data?.isSelectCourse && res.data.gkTotalScore) {
          state.afterMsgCb?.()
        }
        if (typeof okCb === 'function') okCb()
        dispatch({ type: 'updateMsgCb', payload: null })
      }
    })
  }

  const onCheckOk = async () => {
    addRlog('click', {
      button_name: '二次确认弹窗确定'
    })
    const res = await volunteerSave(gkMsgData as IVolunteerData)
    if (res.code === 0) {
      await getVolunteerFun()
      // 是否需要位次确认弹窗  需要则阻止后续流程
      if (res.data) {
        dispatch({
          type: 'updateRankCheckModel',
          payload: true
        })
        setCheckVisible(false)
        return
      }
      message.success('保存成功')
      setCheckVisible(false)
      dispatch({ type: 'updatePersoninfoModel', payload: false })
      dispatch({ type: 'updatePersoninfoExist', payload: true })
    } else {
      message.error(res.description)
    }
  }

  const onCheckCancel = () => {
    addRlog('click', {
      button_name: '二次确认弹窗修改'
    })
    setCheckVisible(false)
    setStep(1)
  }

  const onCloseCancel = () => {
    setCloseVisible(false)
  }

  const onCloseOk = () => {
    const buttonName = {
      1: '高考信息关闭按钮',
      2: '个人信息弹窗关闭按钮'
    }
    addRlog('click', {
      button_name: buttonName[step]
    })
    if (checkVisible) setCheckVisible(false)
    if (closeVisible) setCloseVisible(false)
    dispatch({ type: 'updatePersoninfoModel', payload: false })
    // pad 关闭二合一直接退回平板首页，关闭弹窗的回调
    if (typeof closeCb === 'function') closeCb()
  }

  useEffect(() => {
    if (step === 3) {
      setMsgVisible(false)
      setCheckVisible(true)
    } else {
      setMsgVisible(true)
      setCheckVisible(false)
    }
  }, [step])

  useEffect(() => {
    if (msgVisible) {
      addRlog('dialog', {
        dialog_name: '二合一弹窗'
      })
    } else {
      const buttonName = {
        1: '高考信息关闭按钮',
        2: '个人信息弹窗关闭按钮'
      }
      addRlog('click', {
        button_name: buttonName[step]
      })
    }
  }, [msgVisible])

  useEffect(() => {
    if (checkVisible) {
      addRlog('dialog', {
        dialog_name: '二次确认弹窗'
      })
    }
  }, [checkVisible])

  useEffect(() => {
    setBaseMsgData({ ...state.userInfo })
  }, [state.userInfo])

  return (
    <>
      <Modal
        visible={msgVisible}
        onCancel={onCancel}
        title=""
        wrapClassName={s.percenterModal}
        wrapContentClassName={s.percenterModalContent}
      >
        <>
          <ul className={s.perCenterContent}>
            <li className={s.active}>
              <div className={s.progress}>
                <div className={s.pot} style={{ marginRight: -2 }}>
                  1
                </div>
                <div className={s.line} />
              </div>
              <p className={s.gkMsg}>高考信息</p>
            </li>
            <li className={step === 2 ? s.active : ''}>
              <div className={s.progress}>
                <div className={s.line} style={{ margin: '0 -2px' }} />
                <div className={s.pot}>2</div>
              </div>
              <p className={s.baseMsg}>基本信息</p>
            </li>
          </ul>
          {step === 1 && <GkMsg onOkCb={onOkCbGk} value={gkMsgData} />}
          {step === 2 && (
            <PerBaseMsg
              onOkCb={onOkCbPer}
              onCancelCb={onCancelCbPer}
              toPrev={() => setStep(1)}
              toNext={() => setStep(3)}
              value={baseMsgData}
            />
          )}
        </>
      </Modal>
      <Modal
        visible={checkVisible}
        onCancel={onCheckCancel}
        onOk={onCheckOk}
        title=""
        okButtonText="确认"
        cancelButtonText="修改"
        footer="okAndCancel"
        closable={false}
        wrapClassName={s.checkModal}
        maskClosable={false}
      >
        <section className={s.checkContent}>
          <header>请确认高考地区是否为</header>
          <h3>{gkMsgData?.gkProvince}</h3>
          <p>
            <img src={tipsImg} alt="" />
            高考省份一经确认不可修改
          </p>
        </section>
      </Modal>
      <Modal
        visible={closeVisible}
        onCancel={onCloseCancel}
        onOk={onCloseOk}
        title={closeTitle}
        okButtonText="关闭"
        cancelButtonText="取消"
        footer="okAndCancel"
        wrapClassName={s.checkModal}
      />
    </>
  )
}

PerCenterModal.defaultProps = {
  closeCb: undefined,
  okCb: undefined
}

export default PerCenterModal
